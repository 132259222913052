import React, { useEffect, useRef  } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import MessageBox from "./subComponents/Mess";
import { useDispatch, useSelector } from "react-redux";
import {
  getRolePermission,
  isUserSignedIn,
} from "../redux/reducers/secretState";
import {
  updateField,
  submitAdMessage,
  resetMessage,
} from "../redux/reducers/ContactState";

import {
  getNotifications,
  getUserById,
  setExpandAllMessages,
  setSideBarShow,
} from "../redux/reducers/dashState";
import { handleGetUserData } from "../redux/reducers/UserState";
import SideBar from "./subComponents/SideBar";

function DashMessages() {
  const messagesContainerRef = useRef(null);
  const isSignedIn = useSelector((state) => state.secs.isSignedIn);
  const rolePermission = useSelector((state) => state.secs.rolePermission);
  const notificationsData = useSelector((state) => state.dash.notificationData);
  const userByIdData = useSelector((state) => state.dash.userById);
  const contactData = useSelector((state) => state.contact);
  const user = useSelector((state) => state.user);
  const isAllOpen = useSelector((state) => state.dash.expandAllMessages);

  const dispatch = useDispatch();

  useEffect(() => {
    if (rolePermission === null) {
      dispatch(getRolePermission());
    }
    if (isSignedIn === null) {
      dispatch(isUserSignedIn(true));
    }
    dispatch(getNotifications());
    dispatch(handleGetUserData());

  }, [isSignedIn, dispatch, userByIdData, rolePermission ]);

  useEffect(() => {
    if (messagesContainerRef.current && userByIdData) {
      const element = messagesContainerRef.current;
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [userByIdData]);


  const getMessagesByUserId = (userId) => {
    dispatch(getUserById(userId));
    dispatch(setSideBarShow(false));
  };


  const RenderNotifs = () => {
    if (isSignedIn && notificationsData.length > 0) {
      return notificationsData.map((m) => (
        <div key={m._id} onClick={() => getMessagesByUserId(m._id)}>
          <div type="button" className="btn btn-primary position-relative my-2">
            {m.fullName}
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {m.unreadMessagesCount}
              <span className="visually-hidden">unread messages</span>
            </span>
          </div>
        </div>
      ));
    }
    return null;
  };

  const handleSubmit = () => {
    if (isSignedIn && rolePermission) {
      dispatch(submitAdMessage(contactData));
      dispatch(resetMessage());
    }
  };

  const handleFieldChange = (field, value) => {
    dispatch(updateField({ field, value }));
  };

  const handleExpandAll = () => {
    dispatch(setExpandAllMessages(!isAllOpen));
  };

  const RenderUserMessages = () => {
    if (isSignedIn && userByIdData) {
      return userByIdData.messageingThread.map((m) => (
        <div key={m._id}>
          <MessageBox messageData={m} userId={user._id} />
        </div>
      ));
    }
    return null;
  };

  if (!isSignedIn) {
    return <div>Please login</div>;
  }

  if (isSignedIn && !rolePermission) {
    return (
      <div>
        <div>You are not authorized</div>
      </div>
    );
  }

  const showSideBar = (val) => {
    dispatch(setSideBarShow(val));
  };

  return (
    <div className="container-fluid padding-buttom-100px">
      <div className="row messages_Box">
        <div className="col col-12 my-3 d-flex allign-items-center justify-content-end">
          <button className="btn btn-primary" onClick={() => showSideBar(true)}>
            See the messages
          </button>
        </div>

        <div className="col col-lg-12 scrollable_Box">
          {RenderUserMessages()}
          <div className="sticky-buutom bg-white">
            <Form >
              <Row>
                <Col md={12} className="mb-2">
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    onChange={(e) => {
                      handleFieldChange("message", e.target.value);
                    }}
                    value={contactData.message}
                    tabIndex={8}
                   
                  />
                </Col>
              </Row>
              <Row className="my-1 justify-content-center">
                <Col sm={9} md={9} lg={3}>
                  <Form.Control
                    name="submitBtn"
                    as="button"
                    type="button"
                    className="btn btn-primary my-1"
                    tabIndex={9}
                    onClick={handleSubmit}
                  >
                    Send
                  </Form.Control>
                </Col>
                <Col sm={3} md={3} lg={3}>
                  <Form.Control
                    name="submitBtn"
                    as="button"
                    type="button"
                    className="btn btn-secondary my-1"
                    tabIndex={9}
                    onClick={handleExpandAll}
                    ref={messagesContainerRef}
                  >
                    {isAllOpen ? "Collapse All" : "Expand All"}
                  </Form.Control>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <SideBar>{RenderNotifs()}</SideBar>
      </div>
    </div>
  );
}

export default DashMessages;
