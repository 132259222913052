import { createSlice } from "@reduxjs/toolkit";
import { addToReceivedEmails, updateAnEmail } from "./EmailFormState";

export const websocketSlice = createSlice({
  name: "websocket",
  initialState: {
    isConnected: false,
    messages: [],
  },
  reducers: {
    connected: (state) => {
      state.isConnected = true;
    },
    disconnected: (state) => {
      state.isConnected = false;
    },
  },
});

export const { connected, disconnected, messageReceived } =
  websocketSlice.actions;

export const connectWebsocket = () => (dispatch) => {
  dispatch({ type: "websocket/connect" });
};

export const disconnectWebsocket = () => (dispatch) => {
  dispatch({ type: "websocket/disconnect" });
};

export const sendMessage = (message) => (dispatch) => {
  dispatch({ type: "websocket/send", payload: message });
};

export const addEmailInEmailState = (newEmail) => (dispatch) => {
  dispatch(addToReceivedEmails(newEmail));
};
export const updateEmailInState = (data) => (dispatch) => {
  dispatch(updateAnEmail(data));
};

export default websocketSlice.reducer;
