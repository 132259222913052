import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToken, setError } from "../../redux/reducers/captchaState";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ReCAPTCHAComponent = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const handleVerify = async () => {
      if (!executeRecaptcha) {
        console.error("Execute recaptcha not yet available");
        return;
      }

      try {
        const token = await executeRecaptcha("submit");
        if (token) {
          dispatch(setToken(token));
        } else {
          dispatch(setError("reCAPTCHA failed or response was null"));
        }
      } catch (error) {
        console.error("Error executing recaptcha:", error);
        dispatch(setError("reCAPTCHA execution error"));
      }
    };

    handleVerify();
  }, [dispatch, executeRecaptcha]);

  return null;
};

export default ReCAPTCHAComponent;