import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setGeneralModelShow } from "../redux/reducers/generalUseState";

function GeneralModal(props) {
const dispatch = useDispatch();
const showModal = useSelector((state) => state.general.generalModelShow);

  const handleClose = () => {
    dispatch(setGeneralModelShow(false));
  }


  return (
    <Modal
      {...props}
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.header ? (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.header}
          </Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body>
        {props.children? props.children : null }
      </Modal.Body>
      {props.footer && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default GeneralModal;
