// src/components/FlipperPage.js
import React from 'react';
import { useSelector } from 'react-redux';


const FlipperPage = ({ children }) => {
  const isFlipped = useSelector(state=>state.general.isFlipped);

  return (
    <div className={`flip-container ${isFlipped ? 'flipped' : ''}`}>
      <div className="flipper">
        {React.Children.map(children, (child, index) => (
          <div key={index} className={`face ${index === 0 ? 'front' : 'back'}`}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlipperPage;
