import { createSlice } from "@reduxjs/toolkit";
import { addMessage } from "./UserState";
import { setFormSubmitMessageApi } from "./GlobalErrorState";
import {setUserById} from './dashState';

const initialState = {
  fullName: "",
  companyName: "",
  normalizedEmail: "",
  phoneNumber: "",
  links: "",
  preferredContactMethods: ["email"],
  message: "",
  isLoading: false,
  responseOk: false,
  isGoogleSignInLoading: false,
  isGoogleSignInSuccess: false,
  googleSignInError: null,
  err: "Thank you for your time, I will be in touch shortly",
  success: false,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    updateField: (state, action) => {
      const { field, value } = action.payload;
      if (field in state) state[field] = value;
    },
    toggleContactMethod: (state, action) => {
      const method = action.payload;
      if (state.preferredContactMethods.includes(method)) {
        state.preferredContactMethods = state.preferredContactMethods.filter(
          (m) => m !== method
        );
      } else {
        state.preferredContactMethods.push(method);
      }
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
    resetForm: () => {
      return initialState;
    },
    resetMessage: (state) => {
      state.message = initialState.message;
    },
    responseIsOk: (state) => {
      state.responseOk = true;
    },
    responseIsNotOk: (state) => {
      state.responseOk = false;
    },
    startGoogleSignInLoading: (state) => {
      state.isGoogleSignInLoading = true;
    },
    googleSignInSuccess: (state) => {
      state.isGoogleSignInLoading = false;
      state.isGoogleSignInSuccess = true;
    },
    googleSignInFailure: (state) => {
      state.isGoogleSignInLoading = false;
      state.isGoogleSignInSuccess = false;
    },
    setGoogleSignInError: (state, action) => {
      state.googleSignInError = action.payload;
    },
    setTempT: (state, action) => {
      state.tempT = action.payload;
    }, 
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const {
  updateField,
  toggleContactMethod,
  startLoading,
  endLoading,
  resetForm,
  resetMessage,
  responseIsOk,
  responseIsNotOk,
  startGoogleSignInLoading,
  googleSignInSuccess,
  googleSignInFailure,
  setGoogleSignInError,
  setTempT,
  setSuccess,
} = contactSlice.actions;

export const submitMessage = (contactData) => async (dispatch, getState) => {
  dispatch(startLoading());
  const isSignedIn = getState().secs.isSignedIn;
  const isSpammer = getState().recaptcha.score < 0.6
  try {
   
    const endpoint = isSignedIn
      ? "/Messages/createMessage"
      : "/Messages/createAnonymousMessage";

      if (isSignedIn && isSpammer) {
        console.log(getState().recaptcha.score)
        return;
      }

    const res = await fetch(process.env.REACT_APP_API_BaseURl + endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactData),
    });

    const jsonResponse = await res.json();
    dispatch(setSuccess(jsonResponse.success));

    if (jsonResponse.success) {
      dispatch(addMessage(jsonResponse.newMessage));
      dispatch(responseIsOk());
      dispatch(setFormSubmitMessageApi(jsonResponse.err));
      if (!isSignedIn) {
        dispatch(resetMessage());
      }
    } else {
      dispatch(responseIsNotOk());
      dispatch(setFormSubmitMessageApi(jsonResponse.err));
    }
  } catch (error) {
    console.error(error);
    dispatch(responseIsNotOk());
  }

  dispatch(endLoading());
};



export const submitAdMessage = (contactData) => async (dispatch, getState) => {
  const isSignedIn = getState().secs.isSignedIn;
  try {
    if (isSignedIn) {
       const updatedContactData = {
         ...contactData,
         messageReceiverId:
           getState().dash.userById._id ||
           getState().dash.userById.messageingThread[0]._id,
       };
      const res = await fetch(
        process.env.REACT_APP_API_BaseURl + "/Messages/createAdminMessage",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedContactData),
        }
      );

      const jsonResponse = await res.json();
      
      dispatch(setSuccess(jsonResponse.success));
      if (jsonResponse.success) {
        dispatch(setUserById(jsonResponse.newMessage));
        dispatch(resetMessage());
      } else {
        dispatch(responseIsNotOk());
        dispatch(setFormSubmitMessageApi(jsonResponse.err));
      }
    } 
  } catch (error) {
    console.error(error);
    dispatch(responseIsNotOk());
  }

  dispatch(endLoading());
};


export default contactSlice.reducer;
