import { createSlice } from "@reduxjs/toolkit";
import { setEmailrelatedMessages } from "./GlobalErrorState";
import {
  setEmailModalStatus,
  setNotificationModal,
  setPickNotificationMessageFrom,
} from "./secretState";
import {
  newestEmailOfEachUniqueThreadId,
  sortEmailAcs,
} from "../../utilities/EmailUtils";

const initialState = {
  email: {
    to: [],
    from: "",
    inReplyTo: "",
    references: "",
    cc: [],
    bcc: [],
    subject: "",
    text: "",
    html: "",
    messageId: "",
    date: new Date().toISOString(),
    sentFrom: null,
  },
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  receivedEmails: [],
  repliesArray: [],
  emailSideBarSelection: "",
  searchBy: "",
  folders: [],
  foldersAndCounts: [],
  detailPageOpen: false,
  repliesPage: false,
  fetchEmailsInProcess: false,
  toBeDeleted: [],
  pageNumber: 1,
  totalEmailCountInFolder: null,
};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
    setDetailPageOpen: (state, action) => {
      state.detailPageOpen = action.payload;
    },
    setFetchEmailsInProcess: (state, action) => {
      state.fetchEmailsInProcess = action.payload;
    },
    setFolders: (state, action) => {
      state.folders = action.payload;
    },
    setReceivedEmails: (state, action) => {
      state.receivedEmails = action.payload;
    },
    addToReceivedEmails: (state, action) => {
      if (
        action.payload.to[0].split("@")[0].toLowerCase() ===
        state.emailSideBarSelection.toLowerCase()
      ) {
        state.receivedEmails = newestEmailOfEachUniqueThreadId([
          ...state.receivedEmails,
          action.payload,
        ]);
      }
    },
    addTheReference: (state, action) => {
      let tempRef = state.email.references ? state.email.references + " " : "";
      tempRef += action.payload;
      state.email.references = tempRef.trim();
    },

    setEmailSideBarSelection: (state, action) => {
      state.emailSideBarSelection = action.payload;
    },
    updateEmailFields: (state, action) => {
      const { field, value } = action.payload;
      if (["to", "cc", "bcc"].includes(field)) {
        if (value && value.length > 0) {
          state.email[field] = value.split(","); 
        }
      } else {
        state.email[field] = value;
      }
    },
    deleteEmailsFromState: (state, action) => {
      const mssgId = action.payload;
      state.receivedEmails = state.receivedEmails.filter(
        (each) => each.messageId !== mssgId
      );
    },
    resetEmail: (state) => {
      state.email = { ...initialState.email };
    },

    updateAnEmail: (state, action) => {
      const updatedEmail = action.payload;

      const index = state.receivedEmails.findIndex(
        (email) => email.messageid === updatedEmail.messageid
      );

      if (index !== -1) {
        state.receivedEmails[index] = updatedEmail;
      }
    },

    setToBeDeletedArray: (state, action) => {
      const msgId = action.payload;
      if (state.toBeDeleted.includes(msgId)) {
        state.toBeDeleted = state.toBeDeleted.filter((each) => each !== msgId);
      } else {
        state.toBeDeleted = [...state.toBeDeleted, msgId];
      }
    },
    setFoldersAndCounts: (state, action) => {
      state.foldersAndCounts = action.payload;
    },
    setRepliesArray: (state, action) => {
      state.repliesArray = action.payload;
    },
    setRepliesPage: (state, action) => {
      state.repliesPage = action.payload;
    },
    setEmailStateFields: (state, action) => {
      const { field, value } = action.payload;
      if (value instanceof Date) {
        state[field] = value.toISOString();
      } else {
        state[field] = value;
      }
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setTotalEmailCountInFolder: (state, action) => {
      state.totalEmailCountInFolder = action.payload;
    },
  },
});

export const {
  resetEmail,
  updateEmailFields,
  setFolders,
  setEmailSideBarSelection,
  setReceivedEmails,
  setDateRangeToFilter,
  setSearchBy,
  setDetailPageOpen,
  setFetchEmailsInProcess,
  addToReceivedEmails,
  addTheReference,
  deleteEmailsFromState,
  updateAnEmail,
  setToBeDeletedArray,
  setFoldersAndCounts,
  setRepliesArray,
  setRepliesPage,
  setEmailStateFields,
  setPageNumber,
  setTotalEmailCountInFolder,
} = emailSlice.actions;

export const SendTheEmail = (EmailObject, files) => async (dispatch) => {
  try {
    const formData = new FormData();

    if (files.length > 0) {
      files.forEach((file) => {
        formData.append("attachments", file);
      });
    }

    Object.keys(EmailObject).forEach((key) => {
      if (Array.isArray(EmailObject[key])) {
        formData.append(key, JSON.stringify(EmailObject[key]));
      } else {
        formData.append(key, EmailObject[key]);
      }
    });

    const res = await fetch(
      `${process.env.REACT_APP_API_BaseURl}/email/sendemail`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );

    const response = await res.json();

    if (response.success) {
      dispatch(setEmailrelatedMessages("Email successfully sent"));
      dispatch(resetEmail());
      dispatch(setEmailModalStatus(false));
      await dispatch(setPickNotificationMessageFrom("emailRelatedMessages"));
      dispatch(setNotificationModal(true));
    } else {
      dispatch(setEmailrelatedMessages("Error sending the Email"));
    }
  } catch (err) {
    console.error("Error in fetch:", err);
  }
};

export const getFoldersFromB = () => async (dispatch) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_BaseURl}/email/getPrefixes`,
      {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      }
    );

    const response = await res.json();

    if (res.ok) {
      const tempFolders = [];
      const folderNames = [];

      response.folders.forEach((each) => {
        folderNames.push(each.replace("/", ""));
      });

      dispatch(setFolders(folderNames));

      response.folders.forEach((element) => {
        const temp = {};
        temp.folder = element.replace("/", "");
        const counts = response.count.filter(
          (e) => e.name === temp.folder.toLowerCase()
        );
        const uniteThem = { ...temp, ...counts[0] };
        tempFolders.push(uniteThem);
      });

      dispatch(setFoldersAndCounts(tempFolders));
    } else {
      dispatch(setEmailrelatedMessages(response.err));
      await dispatch(setPickNotificationMessageFrom("emailRelatedMessages"));
      dispatch(setNotificationModal(true));
    }
  } catch (err) {
    dispatch(setEmailrelatedMessages("Error in fetching Endpoint"));
  }
};

export const deleteEmails = (messageIds) => async (dispatch) => {
  try {
    const result = await fetch(
      `${process.env.REACT_APP_API_BaseURl}/dbdb/deleteEmails`,
      {
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ messageIds }),
      }
    );

    const data = await result.json();

    if (data.success) {
      data.deletedEmailIds.forEach((each) => {
        dispatch(deleteEmailsFromState(each));
      });
    }
  } catch (err) {
    console.log(err);
  }
};

//TODO: Not completed yet
export const trashEmails = (messageIds) => async (dispatch) => {
  try {
    const result = await fetch(
      `${process.env.REACT_APP_API_BaseURl}/dbdb/trashEmailsDb`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ids: messageIds }),
      }
    );

    await result.json();
  } catch (err) {
    console.log(err);
  }
};

export const markReadUnread = (messageIds, status) => async (dispatch) => {
  try {
    const result = await fetch(
      `${process.env.REACT_APP_API_BaseURl}/dbdb/readEmailsDb`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ids: messageIds, status }),
      }
    );

    await result.json();
  } catch (err) {
    console.log(err);
  }
};

//general email page
export const GetEmailsFromDb_GeneralView =
  (prefix, num) => async (dispatch) => {
    try {
      dispatch(setFetchEmailsInProcess(true));

      const res = await fetch(
        `${process.env.REACT_APP_API_BaseURl}/dbdb/getEmailsDb?prefix=${prefix}&pageNum=${num}`,
        {
          method: "GET",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        }
      );

      const response = await res.json();

      if (res.ok) {
        const threads = newestEmailOfEachUniqueThreadId(response.emails);

        if (response.count) {
          dispatch(setTotalEmailCountInFolder(response.count));
        }
        dispatch(setReceivedEmails(threads));
      } else {
        dispatch(setEmailrelatedMessages("Could Not Retrieve the Emails"));
        await dispatch(setPickNotificationMessageFrom("emailRelatedMessages"));
        dispatch(setNotificationModal(true));
      }
      if (res.ok) {
        dispatch(setFetchEmailsInProcess(false));
      }
    } catch (err) {
      console.log(err);
    }
  };

//Navbar email with dates
export const GetEmailsThreadsByDate_Navbar =
  (prefix, startDate, endDate) => async (dispatch) => {
    prefix = prefix.toLowerCase();
    try {
      dispatch(setFetchEmailsInProcess(true));
      const res = await fetch(
        `${process.env.REACT_APP_API_BaseURl}/dbdb/threadEmailsDb`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ prefix, startDate, endDate }),
        }
      );

      const response = await res.json();

      if (res.ok) {
        const threads = newestEmailOfEachUniqueThreadId(response.emails);
        if (response.count) {
          dispatch(setTotalEmailCountInFolder(response.count));
        }
        dispatch(setReceivedEmails(threads));
      } else {
        dispatch(setEmailrelatedMessages("Could Not Retrieve the Emails"));
        await dispatch(setPickNotificationMessageFrom("emailRelatedMessages"));
        dispatch(setNotificationModal(true));
      }

      if (res.ok) {
        dispatch(setFetchEmailsInProcess(false));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const getReplies = (threadId) => async (dispatch) => {
  try {
    dispatch(setFetchEmailsInProcess(true));
    const res = await fetch(
      `${process.env.REACT_APP_API_BaseURl}/dbdb/getReplies?threadId=${threadId}`,
      {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      }
    );

    const result = await res.json();

    if (res.ok) {
      dispatch(setRepliesArray(sortEmailAcs(result.emails)));
      dispatch(setFetchEmailsInProcess(false));
    } else {
      //TODO: Set Alarm to notify user
    }
  } catch (err) {
    console.log(err);
  }
};

export default emailSlice.reducer;
