import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleGetUserData } from "../redux/reducers/UserState";

const Temp = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        const postLoginRedirect = localStorage.getItem("postLoginRedirect");
        await dispatch(handleGetUserData(token));
        let TheName = user.fullName;
        if (TheName) {
          TheName = TheName.replace(" ", "_");

          navigate(postLoginRedirect || `/contact/${TheName}`);
           localStorage.removeItem("postLoginRedirect");
        } else {
          navigate(`/contact`);
        }
      }
    };

    fetchData();
  }, [token, navigate, user, dispatch]);

  return <div>Redirecting to the App ...</div>;
};

export default Temp;
