import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { setEmailModalStatus } from "../../redux/reducers/secretState";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Dropdown } from "react-bootstrap";
import NotificationModal from "../subComponents/NotificationModal";

import {
  getFoldersFromB,
  resetEmail,
  updateEmailFields,
  SendTheEmail,
} from "../../redux/reducers/EmailFormState";
import { useEffect, useState } from "react";
import AttachmentDisplay from "./AttachmentNamesDisplay";
import CodeEditor from "../subComponents/HtmlEditor";

function ComposeEmailModal() {
  const dispatch = useDispatch();
  const [emailAttachments, setEmailAttachments] = useState([]);
  const folders = useSelector((state) => state.emailsState.folders);
  const isOpen = useSelector((state) => state.secs.EmailModalStatus);
  const emailForm = useSelector((state) => state.emailsState.email);
  const emailSideBarSelection = useSelector(
    (state) => state.emailsState.emailSideBarSelection
  );

  const handleUpdateState = (field, value) => {
    dispatch(updateEmailFields({ field, value }));
  };

  const handleUpdateHtml =(value) =>{
    handleUpdateState("html", value);
  }

  useEffect(() => {
    async function getFolders() {
      if (folders.length === 0) {
        dispatch(getFoldersFromB());
      }
      if (folders.length > 0) {
        if (folders.includes(emailSideBarSelection)) {
          dispatch(
            updateEmailFields({
              field: "sentFrom",
              value: emailSideBarSelection,
            })
          );
        } else {
          updateEmailFields({ field: "sentFrom", value: folders[0] });
        }
      }
    }
    if (isOpen) {
      getFolders();
    }
  }, [folders, folders.length, dispatch, isOpen, emailSideBarSelection]);

  const renderDropDownOptions = () => {
    return folders.map((each, index) => (
      <Dropdown.Item key={index} eventKey={each.toLowerCase()}>
        {each}
      </Dropdown.Item>
    ));
  };

  const handleDropDownSelection = (eventKey) => {
    dispatch(updateEmailFields({ field: "sentFrom", value: eventKey }));
  };

  const handleClose = () => {
    dispatch(resetEmail());
    setEmailAttachments([]);
    dispatch(setEmailModalStatus(false));
  };

  const handleSubmitEmail = () => {
    if (!emailForm.sentFrom) {
      return;
    } else {
      dispatch(SendTheEmail(emailForm, emailAttachments));
    }
  };

  const handleSaveDraft = () => {
    console.log(emailForm);
  };

  const handleAttachments = (files) => {
      setEmailAttachments([...emailAttachments, files[0]])
  };

  const handleRemoveAttachment = (attachment) => {
   
     const newState = emailAttachments.filter(
      (obj) => obj !== attachment
    );

    setEmailAttachments(newState);
  }


  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-composeEmail"
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Compose Email ${
            emailForm.inReplyTo ? "(This is a Reply)" : ""
          } `}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form>
                <Form.Group className="mb-3" controlId="emailTo">
                  <Form.Control
                    type="email"
                    value={emailForm.to.join(",")}
                    onChange={(e) => {
                      handleUpdateState("to", e.target.value);
                    }}
                    placeholder="To"
                    autoFocus
                  />
                </Form.Group>

                <Dropdown
                  className="my-3 sentFrom"
                  onSelect={handleDropDownSelection}
                >
                  <span>MailBox to send from: </span>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {emailForm.sentFrom}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{renderDropDownOptions()}</Dropdown.Menu>
                </Dropdown>
                <Form.Group className="mb-3" controlId="emailCc">
                  <Form.Control
                    type="email"
                    value={emailForm.cc.join(",")}
                    placeholder="Cc"
                    onChange={(e) => {
                      handleUpdateState("cc", e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="emailBcc">
                  <Form.Control
                    type="email"
                    placeholder="Bcc"
                    value={emailForm.bcc.join(",")}
                    onChange={(e) => {
                      handleUpdateState("bcc", e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="emailSubject">
                  <Form.Control
                    type="text"
                    value={emailForm.subject}
                    placeholder="Subject"
                    onChange={(e) => {
                      handleUpdateState("subject", e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <div className="d-flex flex-direction-row">
                  <label htmlFor="fileInput" className="attachment_btn">
                    Attache file
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    className="d-none"
                    onChange={(e) => {
                      e.preventDefault();
                      handleAttachments(e.target.files);
                    }}
                  />
                </div>
                <AttachmentDisplay showX={true} onXClicked = {handleRemoveAttachment}>{emailAttachments}</AttachmentDisplay>
                
                  <CodeEditor value={emailForm.text} onChange={handleUpdateHtml} />
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="d-flex justify-content-even align-items-center">
              <Col lg={4}>
                <Button
                  variant="primary"
                  className="email_modal_btn"
                  onClick={handleSubmitEmail}
                >
                  Send
                </Button>
              </Col>
              <Col lg={3}>
                <Button
                  variant="secondary"
                  className="email_modal_btn"
                  onClick={handleSaveDraft}
                >
                  Save to Draft
                </Button>
              </Col>
              <Col lg={2}>
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="email_modal_btn"
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
      <NotificationModal />
    </>
  );
}

export default ComposeEmailModal;
