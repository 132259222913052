import { useDispatch, useSelector } from "react-redux";
import EmailDetail from "./EmailDetails";
import { useEffect } from "react";
import {
  markReadUnread,
  resetEmail,
  setRepliesPage,
} from "../../redux/reducers/EmailFormState";
import  LoaderC  from "../subComponents/Loader";

function DisplayReplies({ threadId }) {
  const dispatch = useDispatch();
  const threadEmails = useSelector((state) => state.emailsState.repliesArray);
  const fetchInProgress = useSelector((state) => state.emailsState.fetchEmailsInProcess);

  useEffect(() => {
    const msgIds = threadEmails
      .filter((each) => !each.isread)
      .map((each) => each.messageid);

    dispatch(markReadUnread(msgIds, true));
  }, [dispatch, threadEmails, threadId]);

  const handleBack = () => {
    dispatch(resetEmail());
    dispatch(setRepliesPage(false));
  };

  return (
    <div>
      {fetchInProgress ? <LoaderC/> : threadEmails.map((each) => (
        <div key={each.emailid}>
          <EmailDetail key={each.emailid} mail={each} />
        </div>
      ))}
      <button
        className="btn btn-warning btn-block w-100 mb-3 stickyButtom"
        onClick={() => handleBack()}
      >
        Back...
      </button>
    </div>
  );
  
}

export default DisplayReplies;
