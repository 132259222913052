import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import "./index.css";
import "./global.css"
import "./App.css";
import "./footer.css";
import "./header.css";
import "./dashboard.css";
import "./emailsPage.css";
import "./mesages.css";
import "./navbar.css";
import "./media-query.css";
import "./NameAnimation.css";

import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import './MainContain.css'

const container = document.getElementById("root");

const root = createRoot(container);

Modal.setAppElement("#root");

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
