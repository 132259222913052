import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePostFormField,
  submitPostForm,
  deletePost,
  editPost,
  resetPostForm,
  getSinglePost,
} from "../redux/reducers/postsFormState";
import CodeEditor from "./subComponents/HtmlEditor";
import SideBar from "./subComponents/SideBar";
import AlertModale from "./subComponents/AlertModal";
import { setSideBarShow } from "../redux/reducers/dashState";
import { getAllPost } from "../redux/reducers/postsState";
import { Card, CardBody, Col, Row } from "react-bootstrap";

const DashBoardPosts = () => {
  const dispatch = useDispatch();

  const title = useSelector((state) => state.postForm.title);
  const category = useSelector((state) => state.postForm.category);
  const content = useSelector((state) => state.postForm.content);
  const PostFormData = useSelector((state) => state.postForm);
  const [showAlert, setShowAlert] = useState(false);
  const posts = useSelector((state) => state.posts.posts);

  const handleInputChange = (field) => (e) => {
    dispatch(updatePostFormField({ field, value: e.target.value }));
  };

  const handleContentChange = (newValue) => {
    dispatch(updatePostFormField({ field: "content", value: newValue }));
  };

  const handleSubmit = (event) => {
    const isFormValid = Object.values(PostFormData).every(
      (value) => value.trim() !== ""
    );
    if (isFormValid) {
      dispatch(submitPostForm(PostFormData));
    } else {
      event.preventDefault();
      alert("Please fill all fields");
    }
  };

  const handleDeletePost = () => {
    dispatch(deletePost(PostFormData._id));
  };

  const handleEditPost = () => {
    dispatch(editPost(PostFormData));
  };

  const showSideBar = (val) => {
    dispatch(setSideBarShow(val));
    if (!posts || posts.length === 0) {
      dispatch(getAllPost());
    }
  };
  const showTheAlert = (val) => {
    setShowAlert(val);
  };

  const getAPost = async (id) => {
    dispatch(getSinglePost(id));
    dispatch(setSideBarShow(false));
  };

  const Poses = posts.map((m) => (
    <Col key={m._id} sm={12} md={3} className="d-flex mx-2 p-3 flex-column flex-grow-1">
      <Card className="w-100 h-100 shadow">
        <CardBody>
          <div>{m.category}</div>
          <h3 onClick={() => getAPost(m._id)}>{m.title}</h3>
        </CardBody>
      </Card>
    </Col>
  ));

  return (
    <div className="container-fluid bb mxh100">
      <form onSubmit={handleSubmit}>
        <Row className="p-3 align-items-center justify-content-center">
          <Col sm={12} md={6}>
            <button
              className="btn btn-primary btn-block mt-3 mx-1 w-100"
              type="submit"
            >
              Submit New Post
            </button>
          </Col>

          <Col sm={12} md={6}>
            <button
              className="btn btn-success btn-block mt-3 mx-1 w-100 "
              type="button"
              onClick={() => showSideBar(true)}
            >
              Choose a Post
            </button>
          </Col>
          <Col sm={1} md={12}>
            <div className="my-3">
              <label htmlFor="categoryInput">Category</label>
              <input
                id="categoryInput"
                value={category}
                onChange={handleInputChange("category")}
                type="text"
                placeholder="Category"
                className="form-control"
              />
            </div>
          </Col>
          <Col sm={3} md={12}>
            <div className="my-3">
              <label htmlFor="titleInput">Title</label>
              <input
                id="titleInput"
                value={title}
                onChange={handleInputChange("title")}
                type="text"
                placeholder="Title"
                className="form-control"
              />
            </div>
          </Col>
        </Row>
        <Row className="d-felx">
          <Col className="" md={12}>
            <CodeEditor value={content} onChange={handleContentChange} />
          </Col>
        </Row>
      </form>
      <Row className="my-5">
        <Col className="d-flex" sm={12} md={6} lg={4}>
          <button
            className="btn btn-warning btn-block mt-3 mx-1 w-100"
            type="button"
            onClick={() => {
              dispatch(resetPostForm());
            }}
          >
            Clear The Form
          </button>
        </Col>

        <Col className="d-flex" sm={12} md={6} lg={4}>
          <button
            className="btn btn-success btn-block mt-3 mx-1 w-100"
            type="button"
            onClick={handleEditPost}
          >
            Edit The Post
          </button>
        </Col>
        <Col className="d-flex justify-content-start" sm={12} lg={4}>
          <button
            className="btn btn-danger btn-block mt-3 mx-1 w-100"
            type="button"
            onClick={showTheAlert}
          >
            Delete The Post
          </button>
        </Col>
      </Row>

      <div>
        <SideBar>{Poses}</SideBar>
        <AlertModale
          showAlert={showAlert}
          fn={showTheAlert}
          result={handleDeletePost}
        />
      </div>
    </div>
  );
};

export default DashBoardPosts;
