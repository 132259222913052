import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <footer className="footerWrapper text-center p-3">
      <div className="d-flex justify-content-center align-items-end mb-2">
        <a
          style={{ textDecoration: "none" }}
          href="https://www.linkedin.com/in/yasharalee/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon mx-2"
        >
          <span>LinkedIn </span>
          <FaLinkedin />
        </a>
        <div className="divider"></div>
        <Link to="/contact" className="link mx-2">
          Contact Me
        </Link>
      </div>
      <small>Conceptualized and Developed by Yashar Alee</small>
    </footer>
  );
}

export default Footer;
