export const validateFullName = (value) => {
    if (value.length < 5 || value.trim().length < 5) {
        return 'Full name must be at least 5 characters';
    }
};

export const validateCompanyName = (value) => {
    if (!value || value.trim() === '' || value.startsWith(' ') || value.endsWith(' ')) {
        return 'Company name format is not valid';
    }
};


export const validateEmail = (value) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
        return 'Valid email is required';
    }
};

export const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]{10,15}$/;
    if (value && !phoneRegex.test(value)) {
        return 'Invalid phone number format';
    }
};

export const validateLinks = (value) => {
    const linkRegex = /^(https?:\/\/)?([\w\d.-]+)\.([a-zA-Z.]{2,6})([/\w .-]*)*\/?$/;
    if (value && !linkRegex.test(value)) {
        return 'Invalid link format';
    }
};

export const validateMessage = (value) => {
    if (value.trim().length < 10 || value.length > 2000) {
        return value.trim().length < 10 ? 'Message must be at least 10 characters' : 'Message should not exceed 2000 characters';
    }
};
