import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "",
  title: "",
  content: "",
};

export const postsFormSlice = createSlice({
  name: "postsForm",
  initialState,
  reducers: {
    updatePostFormField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetPostForm: (state, action) => {
      return initialState;
    },
  },
  // asynchronous actions
});

export const { updatePostFormField, resetPostForm } = postsFormSlice.actions;

export const submitPostForm = (PostData) => async (dispatch) => {
  try {
    const res = await fetch(
      process.env.REACT_APP_API_BaseURl + "/posts/createPost",
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(PostData),
      }
    );
    const data = await res.json();

    if (!data.success) {
      console.log("Post failed to create");
    }else{
      dispatch(resetPostForm);
    }
  } catch (err) {
    console.log("Error in fetch");
  }
};


export const getSinglePost = (id) => async (dispatch, getState) => {
  try {
    const res = await fetch(
      process.env.REACT_APP_API_BaseURl + `/posts/getPost/${id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const resJson = await res.json();

    if (res.ok) {
     if (res.ok) {
       const postData = resJson.data[0];

       for (const key in postData) {
         if (postData.hasOwnProperty(key)) {
           dispatch(updatePostFormField({ field: key, value: postData[key] }));
         }
       }
     }
    } else {
      console.log("No Post Retrived");
    }
  } catch (err) {
    console.log("Error in fetch");
  }
};

export const deletePost = (id) => async (dispatch) => {
  try {
    const res = await fetch(
      process.env.REACT_APP_API_BaseURl + `/posts//deletePost/${id}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();

    if (!data.success) {
      console.log("Could not delete the post, try again");
    } else {
      dispatch(resetPostForm());
    }
  } catch (err) {
    console.log("Error in fetch");
  }
};

export const editPost = (PostData) => async (dispatch) => {
  try {
    const res = await fetch(
      process.env.REACT_APP_API_BaseURl + "/posts/editPost",
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(PostData),
      }
    );
    const data = await res.json();

    if (!data.success) {
      console.log("Could not edit the Post");
    }else{
      dispatch(resetPostForm());
    }
  } catch (err) {
    console.log("Error in fetch");
  }
};



export default postsFormSlice.reducer;
