import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import GeneralModal from "../GeneralModel";
import { setGeneralModelShow } from "../../redux/reducers/generalUseState";

function AttachmentDisplay({ children, showX , onXClicked}) {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(null);
  const show = useSelector((state) => state.general.generalModelShow);


  const handleDownload = (attachment) => {
    const fileUrl = attachment.url;
    window.open(fileUrl, "_blank");
  };


  const handlePreview = (attachment) => {
    const fileName = attachment.originalname;
    const fileUrl = attachment.url;

    if (/\.(jpe?g|png|gif)$/i.test(fileName)) {
      setPreview(
        <img src={fileUrl} alt="preview" style={{ maxWidth: "200px" }} />
      );
    } else if (/\.pdf$/i.test(fileName)) {
      setPreview(
        <iframe
          title="preview pdf"
          src={fileUrl}
          style={{ width: "100%", height: "600px" }}
        />
      );
      dispatch(setGeneralModelShow(true));
    }else{
      handleDownload(attachment);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const renderChildren = () => {
    return children.map((attachment, i) => (
      <div
        className="file-upload"
        key={i}
        onClick={(e) => {
          e.preventDefault();
        }}
        style={{ overflow: showX ? 'visible' : 'hidden' }}
      >
        {showX ? (
          <div
            className="removeX"
            onClick={(e) => {
              onXClicked(attachment);
              e.preventDefault();
            }}
          >
            x
          </div>
        ) : (
          ""
        )}
        {!showX ? <div className="slider_wrapper">
          <div
            className="Preview_slider"
            onClick={(e) => {
              handlePreview(attachment);
              e.preventDefault();
            }}
          >
            Preview
          </div>
          <div
            className="Download_slider"
            onClick={(e) => {
              handleDownload(attachment);
              e.preventDefault();
            }}
          >
            Download
          </div>
        </div> : ""}
        <div>{attachment.name || attachment.originalname}</div>
        <div className="attachement_size">{formatBytes(attachment.size)}</div>
      </div>
    ));
  };

  return (
    <div className="d-flex">
      {renderChildren()}
      {preview && show && <GeneralModal>{preview}</GeneralModal>}
    </div>
  );
}

export default AttachmentDisplay;
