import React, { useState } from "react";
import { Input } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

function SearchBox() {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    console.log("Search for:", searchTerm);
    // Add the search logic here
  };

  return (
    <div className="search-container">
      <Input
        icon={{ name: "search", link: true, onClick: handleSearch }}
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && handleSearch()}
        className="rounded-search-box"
      />
    </div>
  );
}

export default SearchBox;
