import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import websocketMiddleware from "./middlewares/websocketMiddleware";
import storage from "redux-persist/lib/storage";

const ContactTransform = createTransform(
  (inboundState, key) => ({
    fullName: inboundState.fullName,
    companyName: inboundState.companyName,
    normalizedEmail: inboundState.normalizedEmail,
    phoneNumber: inboundState.phoneNumber,
    links: inboundState.links,
    preferredContactMethods: inboundState.preferredContactMethods,
    message: inboundState.message,
  }),
  (outboundState, key) => ({
    ...outboundState,
  }),
  { whitelist: ["contact"] }
);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["contact"],
  transforms: [ContactTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }).concat(websocketMiddleware),
});

export const persistor = persistStore(store);
