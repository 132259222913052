import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const CopyToClipboard = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Copied!
    </Tooltip>
  );

  return (
    <OverlayTrigger show={showTooltip} placement="top" overlay={renderTooltip}>
      <FontAwesomeIcon
        icon={faCopy}
        onClick={copyToClipboard}
        className="copyIcon"
        style={{ cursor: "pointer", marginLeft: "10px", color: "white" }}
      />
    </OverlayTrigger>
  );
};

export default CopyToClipboard;
