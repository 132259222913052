import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formSubmitMessageApi: null,
  rolePermissionErr: null,
  notificationErr: null,
  userByIdErr: null,
  messageingThreadErr: null,
  emailRelatedMessages: null,
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setFormSubmitMessageApi: (state, action) => {
      state.formSubmitMessageApi = action.payload;
    },
    setRolePermissionErr: (state, action) => {
      state.rolePermissionErr = action.payload;
    },
    setEmailrelatedMessages: (state, action) => {
      state.emailRelatedMessages = action.payload;
    },
    setNotificationErr: (state, action) => {
      state.notificationErr = action.payload;
    },
    setUserByIdErr: (state, action) => {
      state.userByIdErr = action.payload;
    },
    setMessageingThreadErr: (state, action) => {
      state.messageingThreadErr = action.payload;
    },
  },
});

export const {
  setFormSubmitMessageApi,
  setRolePermissionErr,
  setNotificationErr,
  setUserByIdErr,
  setMessageingThreadErr,
  setEmailrelatedMessages,
} = errorSlice.actions;
export default errorSlice.reducer;
