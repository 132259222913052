import { createSlice } from "@reduxjs/toolkit";
import { setItemToLocalStorage } from "../../utilities/generalUtils";

const initialState = {
    generalModelShow: false,
    IsNameAnimatedBefore:false,
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  isFlipped: false,
  reducers: {
    setGeneralModelShow: (state, action) => {
      state.generalModelShow = action.payload;
    },
    setIsNameAnimatedBefore: (state, action) => {
      setItemToLocalStorage("INA", true);
      state.IsNameAnimatedBefore = action.payload;
    },
    toggleFlip: (state) => {
      state.isFlipped = !state.isFlipped;
    },
  },
});

export const {
    setGeneralModelShow,
    toggleFlip,
    setIsNameAnimatedBefore

  } = generalSlice.actions;


//   export const SendTheEmail = (EmailObject, files) => async (dispatch) => {
//     try {
     
        
//     } catch (err) {
//       console.error("Error in fetch:", err);
//     }
//   };

export default generalSlice.reducer;