import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFoldersFromB,
  setEmailSideBarSelection,
  setRepliesPage,
  deleteEmails,
  trashEmails,
  GetEmailsFromDb_GeneralView,
  getReplies,
} from "../../redux/reducers/EmailFormState";
import Loader from "../subComponents/Loader";
import {
  connectWebsocket,
  disconnectWebsocket,
} from "../../redux/reducers/websocketState";
import EmailRow from "./EmailRow";
import { extras, getFolderNamesAsPeoperty } from "../../utilities/EmailUtils";
import DisplayReplies from "./DisplayRepliesPage";
import EmailPagination from "./Pagination";
import { Col, Container, Row } from "react-bootstrap";

function DisplayEmailsPage() {
  const dispatch = useDispatch();
  const [emailThreadId, setEmailThreadId] = useState(null);
  const [prevSelectedFolder, setPrevSelectedFolder] = useState(null);
  const emails = useSelector((state) => state.emailsState.receivedEmails);
  const [filteredEmailArray, setFilteredEmailArray] = useState(emails);

  const folders = useSelector((state) => state.emailsState.folders);
  const selectedFolder = useSelector(
    (state) => state.emailsState.emailSideBarSelection
  );
  const repliesPageStatus = useSelector(
    (state) => state.emailsState.repliesPage
  );
  const isFetchingEmailInProgress = useSelector(
    (state) => state.emailsState.fetchEmailsInProcess
  );
  const totalEmailsCount = useSelector(
    (state) => state.emailsState.totalEmailCountInFolder
  );

  const localMessageIdContainer = useSelector(
    (state) => state.emailsState.toBeDeleted
  );
  const pageNumber = useSelector((state) => state.emailsState.pageNumber);

  useEffect(() => {
    dispatch(connectWebsocket());
    return () => {
      dispatch(disconnectWebsocket());
    };
  }, [dispatch]);

  useEffect(() => {
    async function fetchData() {
      if (folders.length === 0) {
        dispatch(getFoldersFromB());
      }
    }

    fetchData();
  }, [dispatch, folders.length]);

  useEffect(() => {
    if (folders.length > 0 && !selectedFolder) {
      dispatch(setEmailSideBarSelection(folders[0]));
    }
  }, [dispatch, folders, selectedFolder]);

  useEffect(() => {
    async function fetchEmails() {
      if (selectedFolder && selectedFolder !== prevSelectedFolder) {
        await dispatch(GetEmailsFromDb_GeneralView(selectedFolder, pageNumber));
        setPrevSelectedFolder(selectedFolder);
      }
    }
    fetchEmails();

    const extraFolders = extras();
    if (extraFolders.includes(selectedFolder)) {
      const propertyName = getFolderNamesAsPeoperty(selectedFolder);
      const filtered = emails.filter((each) => {
        return each[propertyName] === true;
      });
      setFilteredEmailArray(filtered);
    } else {
      const filtered = emails.filter((each) => {
        return each.istrash !== true && each.isdraft !== true;
      });
      setFilteredEmailArray(filtered);
    }
  }, [dispatch, selectedFolder, prevSelectedFolder, emails, pageNumber]);

  const handleRepliesPage = (mail) => {
    setEmailThreadId(mail.customthreadid);
    dispatch(getReplies(mail.customthreadid));
    dispatch(setRepliesPage(true));
  };

  const handleDeletion = () => {
    if (localMessageIdContainer <= 0) {
      alert("Please select an email to delete");
    } else {
      dispatch(deleteEmails(localMessageIdContainer));
    }
  };

  const handleSendToTrash = () => {
    if (localMessageIdContainer <= 0) {
      alert("Please select an email to delete");
    } else {
      dispatch(trashEmails(localMessageIdContainer));
    }
  };

  const renderEmails = () => {
    if (isFetchingEmailInProgress) {
      return <Loader />;
    } else {
      if (filteredEmailArray.length === 0) {
        return (
          <div className="d-flex justify-content-center lign-items-center">
            <div> No Email has found in this folder </div>
          </div>
        );
      } else
        return filteredEmailArray.map((email) => (
          <EmailRow
            key={email.messageId}
            email={email}
            handleEmailDetail={handleRepliesPage}
          />
        ));
    }
  };

  return (
    <Container fluid>
      <Row className="my-4 p-2 align-items-center">
        <Col xs={4} sm={3} lg={2} className="d-flex justify-content-start">
          <h3>{`${repliesPageStatus ? "Email Details" : "Emails"} : `}</h3>
        </Col>
        <Col xs={6} sm={3} lg={2} className="">
          <h4 style={{ color: "blue" }}>{`(${selectedFolder})`}</h4>
        </Col>
        <Col>
          <Row>
            <Col xs={6} sm={6} className="p-4">
              {selectedFolder === "Trash" ? (
                <div
                  role="button"
                  className="btn btn-danger btn-block w-100"
                  onClick={handleDeletion}
                >
                  Delete
                </div>
              ) : (
                <div
                  role="button"
                  className="btn btn-danger btn-block w-100"
                  onClick={handleSendToTrash}
                >
                  Trash
                </div>
              )}
            </Col>
            <Col xs={6} sm={6} className="p-4">
              <div role="button" className="btn btn-warning btn-block w-100">
                Star
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={repliesPageStatus ? "" : "emails_wrapper"}>
        {repliesPageStatus ? (
          <DisplayReplies threadId={emailThreadId} />
        ) : (
          renderEmails()
        )}
      </div>
      <div className="my-3">
        <EmailPagination totalEmails={totalEmailsCount} />
      </div>
    </Container>
  );
}

export default DisplayEmailsPage;
