import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllPost } from "../redux/reducers/postsState";
import Loader from "./subComponents/Loader";
import { setActiveMenu } from "../redux/reducers/activeMenuState";
// import { Fade } from "react-reveal";

function AboutMeDetail() {
  const dispatch = useDispatch();
  const { posts, isLoading, error } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(getAllPost());
    dispatch(setActiveMenu("AboutMeDetail"));
  }, [dispatch]);

  const Poses = posts.map((m) => (
    <Col
      key={m._id}
      xs={12}
      sm={12}
      md={12}
      lg={5}
      className="d-flex flex-column flex-grow-1 my-lg-3 p-1 px-3"
    >
      <div className="d-flex flex-row flex-grow-1">
        {/* <Fade duration={2000} delay={200}> */}
          <Card className=" shadow h-100 " style={{ border: "none" }}>
            <Card.Body>
              <div>{m.category}</div>
              <h3>{m.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: m.content }} />
            </Card.Body>
          </Card>
        {/* </Fade> */}
      </div>
    </Col>
  ));

  if (isLoading)
    return (
      <div>
        {" "}
        <Loader />{" "}
      </div>
    );
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container
      fluid
      className={`full-height-container  ${
        error ? "min-heigth-100 d-flex align-items-center" : ""
      } `}
    >
      <Row
        className={`min-heigth-100 my-2 align-items-stretch justify-content-evenly content-h-s`}
      >
        {Poses}
      </Row>
    </Container>
  );
}

export default AboutMeDetail;
