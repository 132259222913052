
export const sortEmailDesc = (emails) => {
  const sortedEmails = [...emails].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  return sortedEmails;
};

export const sortEmailAcs = (emails) => {
  const sortedEmails = [...emails].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  return sortedEmails;
};

export const getFolderNamesAsPeoperty = (str) => {
  let propertyNameInEmail;

  switch (str) {
    case "Starred":
      propertyNameInEmail = "isstarred";
      break;
    case "Draft":
      propertyNameInEmail = "isdraft";
      break;
    case "Trash":
      propertyNameInEmail = "istrash";
      break;
    case "Sent":
      propertyNameInEmail = "issent";
      break;
    case "Spam":
      propertyNameInEmail = "isspam";
      break;
    case "Blocked":
      propertyNameInEmail = "isblock";
      break;

    default:
      propertyNameInEmail = str;
      break;
  }
  return propertyNameInEmail;
};

export const extras = () => {
  return ["Sent", "Starred", "Draft", "Trash", "Spam", "Blocked"];
};

export const newestEmailOfEachUniqueThreadId = (emails) => {
  const threads = [];
  let threadIds = new Set(emails.map((email) => email.customthreadid));

  threadIds.forEach((threadId) => {
    let temp = emails.filter(
      (email) => email.customthreadid === threadId
    );
    temp.sort((a, b) => new Date(a.date) - new Date(b.date));
    threads.push(temp[temp.length - 1]);
  });

  return  sortEmailDesc(threads);
};
