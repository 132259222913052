import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsNameAnimatedBefore } from "../redux/reducers/generalUseState";
import { setItemToLocalStorage } from "../utilities/generalUtils";

const NameAnimation = ({ onAnimationComplete }) => {
  const dispatch = useDispatch();

  const [showNameComponent, setShowNameComponent] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const isNameAnimated = localStorage.getItem("INA");

    if (isNameAnimated === "false" || isNameAnimated === null) {
      setShowNameComponent(true);

      const timer = setTimeout(() => {
        dispatch(setIsNameAnimatedBefore(true));
        setIsExiting(true);
        setTimeout(() => {
          setShowNameComponent(false);
          setItemToLocalStorage("INA", true);
          onAnimationComplete();
        }, 3000);
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowNameComponent(false);
      onAnimationComplete();
    }
  }, [dispatch, onAnimationComplete]);

  return (
    showNameComponent && (
      <div className={`column-overlay-page ${isExiting ? "exit" : ""}`}>
        <h1 className="animate-name">Yashar Alee</h1>
        <h4 className="animate-title">
          Software Development Engineer in Test "SDET"
        </h4>
      </div>
    )
  );
};

export default NameAnimation;
