
import React from 'react';
import { Editor } from 'primereact/editor';

class CodeEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorValue: props.value || '',
    };
  }

  onTextChange = (e) => {
    const newValue = e.htmlValue;
    if (newValue !== this.state.editorValue) {
      this.setState({ editorValue: newValue }, () => {
        if (this.props.onChange) {
          this.props.onChange(newValue);
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value && this.props.value !== this.state.editorValue) {
      this.setState({ editorValue: this.props.value });
    }
  }

  render() {
    const { editorValue } = this.state;

    return (
      <div className="col-md-12">
        <Editor
          style={{ height: '500px' }}
          value={editorValue}
          onTextChange={this.onTextChange}
        />
      </div>
    );
  }
}

export default CodeEditor;


