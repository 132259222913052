import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const HeaderContent = () => {
  return (
    <Container fluid>
      <Row className="align-items-center justify-content-start header-wrapper">
        <Col sm={4} md={3} lg={2} className="align-items-center justify-content-start">
          <Image
            src="/images/fc.jpg"
            alt="Yashar alee"
            className="img-fluid header-image"
          />
        </Col>
        <Col className="align-items-center justify-content-start">
          <h1>Yashar Alee</h1>
          <h4>Software Development Engineer in Test "SDET"</h4>
        </Col>
      </Row>
    </Container>
  );
};

export default HeaderContent;
