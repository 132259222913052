import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { setNotificationModal } from "../../redux/reducers/secretState";

function NotificationModal() {
  const show = useSelector((state) => state.secs.notificationModal);
  const notifFrom = useSelector(
    (state) => state.secs.pickNotificationMessageFrom
  );

  const dispatch = useDispatch();

  const message = useSelector((state) => state.errors[notifFrom]);

  const handleClose = () => {
    dispatch(setNotificationModal(false));
 
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NotificationModal;
