import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  signInWithGoogle,
  logoutUser,
  signInWithOutLook,
} from "../redux/reducers/UserState";
import { resetForm } from "../redux/reducers/ContactState";

function SigningBtns({ isSignedIn }) {
  useEffect(() => {}, [isSignedIn]);

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const openGoogleSignIn = () => {
    dispatch(signInWithGoogle());
  };

  const openOutLookSignIn = () => {
    dispatch(signInWithOutLook());
  };

  const GoogleSignOutHandler = async () => {
    const success = await dispatch(logoutUser());
    if (success) {
      dispatch(resetForm());
    }
  };

  const loginBtn = () => {
    if (!isSignedIn) {
      return (
        <>
          <div className="col col-sm-6 col-md-6 signBtn">
            <img
              src="/images/btn_google_signin_dark_normal_web.png"
              alt="google sign in button"
              onClick={openGoogleSignIn}
            />
          </div>
          <div className="col col-sm-6 col-md-6 signBtn">
            <img
              src="/images/ms-symbollockup_signin_light.svg"
              alt="google sign in button"
              onClick={openOutLookSignIn}
            />
          </div>
        </>
      );
    } else {
      return (
        <button onClick={GoogleSignOutHandler} className="btn btn-secondary signOutBtn col">
          {" "}
          {`Sign out ${user.fullName}`}{" "}
        </button>
      );
    }
  };
  return (
    <div>
      <div className="row btnRow">{loginBtn()}</div>
    </div>
  );
}

export default SigningBtns;
