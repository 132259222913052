import { createSlice } from "@reduxjs/toolkit";
import { submitMessage, resetMessage } from "./ContactState";
import { setShowThanksMessage } from "./secretState";

const initialState = {
  loading: false,
  error: null,
  score: false,
  token: "",
};

const captchaSlice = createSlice({
  name: "recaptcha",
  initialState,
  reducers: {
    resetRecaptchaState: (state) => {
      state.loading = false;
      state.error = null;
      state.score = 0;
      state.token = "";
      state.errorCode = [];
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setScore: (state, action) => {
      state.score = action.payload;
    },
  },
});

export const {
  resetRecaptchaState,
  setToken,
  setError,
  setScore,
} = captchaSlice.actions;

export const generateRecaptchaToken = (action) => async (dispatch) => {
  if (window.grecaptcha) {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_SITE_KEY, { action })
          .then((token) => {
            dispatch(setToken(token));
            resolve(token);
          })
          .catch((error) => {
            console.error("Error generating reCAPTCHA token:", error);
            reject(error);
          });
      });
    });
  } else {
    throw new Error("reCAPTCHA not loaded");
  }
};

export const verifyRecaptchaToken = (newToken) => async (dispatch) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_BaseURl}/captcha/verify-recaptcha`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: newToken }),
      }
    );

    if (!res.ok) {
      console.error(
        "Failed to verify reCAPTCHA. Response not ok:",
        res.statusText
      );
    }

    const data = await res.json();

    if (!data.success) {
      return false;
    }

    if (data.data.score !== undefined) {
      dispatch(setScore(data.data.score));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    alert("Could not verify the reCAPTCHA");
  }
};

export const verifyRecaptchaAndSubmitForm =
  (formData) => async (dispatch, getState) => {
    const { isSignedIn } = getState().secs;

    try {
      const newToken = await dispatch(generateRecaptchaToken("submit"));

      const verified = await dispatch(verifyRecaptchaToken(newToken));

      const { score } = getState().recaptcha;

      if (verified) {
        if (score > 0.6 || isSignedIn) {
          dispatch(submitMessage(formData));
          dispatch(setShowThanksMessage(true));
          dispatch(resetMessage());
        } else {
          alert(
            "For security reasons, we couldn't verify your activity. Please ensure you are not using automated tools."
          );
        }
      } else {
        alert("Internal error, please try again later");
      }
    } catch (err) {
      dispatch(setError("reCAPTCHA failed or response failed"));
    }
  };

export default captchaSlice.reducer;
