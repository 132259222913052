import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import CopyToClipboard from "../subComponents/CopyToClipboard";

const MessageBox = ({ messageData, userId }) => {
  const [isOpened, setIsOpened] = useState(false);
  const expandAll = useSelector((state) => state.dash.expandAllMessages);

  useEffect(() => {
    setIsOpened(expandAll);
  }, [expandAll]);

  const toggleMessage = () => {
    setIsOpened((prevIsOpened) => !prevIsOpened);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Data along with this message</Popover.Header>
      <Popover.Body>
        <div className="row">
          {messageData.companyName && (
            <div className="col col-12">{`Company: ${messageData.companyName}`}</div>
          )}
          {messageData.normalizedEmail && (
            <div className="col col-12">{`Email: ${messageData.normalizedEmail}`}</div>
          )}
          {messageData.phoneNumber && (
            <div className="col col-12">{`Phone: ${messageData.phoneNumber}`}</div>
          )}
          {messageData.preferredContactMethods && (
            <div className="col col-12">{`Method: ${messageData.preferredContactMethods}`}</div>
          )}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div
        className={` d-flex ${
          messageData.author === userId
            ? "justify-content-start"
            : "justify-content-end"
        }`}
      >
        <div
          className={`card mb-3 p-3 ${
            messageData.author === userId ? "userMessage" : "response"
          }`}
        >
          <div className="my-3 px-3">
            <strong>{messageData.fullName}</strong>
            <span className="mx-2">{` ${moment(messageData.createdAt).format(
              "MMMM D, YYYY, h:mm A"
            )}`}</span>
            <span>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={popover}
                rootClose
              >
                <Button className="btn btn-secondary btn-sm moreInfoBtn">
                  More Info
                </Button>
              </OverlayTrigger>
            </span>
          </div>
          <div className="px-5" style={{color:"orange"}} >
            {messageData.links !== "" ? (
              <span>
                Links: {messageData.links}
                <CopyToClipboard text={messageData.links} />
              </span>
            ) : (
              ""
            )}
          </div>

          <div className={`message-content py-2`}>
            {isOpened || expandAll || messageData.message.length <= 300
              ? messageData.message
              : `${messageData.message.substring(0, 300)}...`}
          </div>
          {messageData.message.length > 300 && (
            <div
              className="toggleMessage"
              onClick={toggleMessage}
              style={{ cursor: "pointer" }}
            >
              {isOpened ? "Show Less" : "Show More"}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageBox;
