import React, { useEffect, useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Contact from "./components/Contact";
import DashboardMain from "./components/DashBoardMain";
import Temp from "./components/Temp";
import AboutMeMain from "./components/AboutMeMain";
import ReCAPTCHAComponent from "./components/subComponents/ReCAPTCHAComponent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  getItemFromLocalStorage,
} from "./utilities/generalUtils";
import NameAnimation from "./components/NameAnimation";
import { useDispatch, useSelector } from "react-redux";
import { getRolePermission } from "./redux/reducers/secretState";



function App() {
  const [isNameAnimatedBefore, setIsNameAnimatedBefore] = useState(null);
  const rolePermission = useSelector((state) => state.secs.rolePermission);
  const dispatch = useDispatch();

  useEffect(()=>{
    if (rolePermission === null) {
      dispatch(getRolePermission());
    }
  },[dispatch,rolePermission])

  useEffect(() => {
    const value = getItemFromLocalStorage("INA") === true;
    setIsNameAnimatedBefore(value);
  }, []);

  const handleNameAnimationComplete = () => {
    setIsNameAnimatedBefore(true);
  };

  const ContactPage = () => {
    return <Contact />;
  };
  
  const DashboardPage = () => {
    return rolePermission ? <DashboardMain /> : <Navigate to="/contact" replace />;

  }

  return (
    <PrimeReactProvider>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
        <Router>
          <div className="App">
            <div className="sticky-top">
              <Header />
              <NavBar />
            </div>
            {isNameAnimatedBefore === false && (
              <NameAnimation
                onAnimationComplete={handleNameAnimationComplete}
              />
            )}

            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/redirect/:token" element={<Temp />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/contact/:token" element={<ContactPage />} />
              <Route path="/AdDashPr/*" element={<DashboardPage />} />
              <Route exact path="/AboutMeDetail" element={<AboutMeMain />} />
              <Route path="*" element={<Navigate to="/" replace />} /> {/* Default route */}
            </Routes>

            <ReCAPTCHAComponent />

            <Footer />
          </div>
        </Router>
      </GoogleReCaptchaProvider>
    </PrimeReactProvider>
  );
}

export default App;
