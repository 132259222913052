import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function AlertModale({ showAlert, fn, result }) {
  const [show, setShow] = useState(showAlert);

  const handleClose = () => {
    setShow(false);
    fn(show);
  };

  return (
    <>
      <Modal show={showAlert} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Deletion Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to DELETE a Post? This action is irreversible
          and will permanently delete the post.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleClose();
              result();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AlertModale;
