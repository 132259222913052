import { combineReducers } from "redux";
import menuReducer from "./activeMenuState";
import contactReducer from "./ContactState";
import userSlice from "./UserState";
import postReducer from "./postsState";
import errorSlice from "./GlobalErrorState";
import secretsSlice from "./secretState";
import DashSlice from "./dashState";
import postsFormSlice from "./postsFormState";
import captchaSlice from "./captchaState";
import emailSlice from "./EmailFormState";
import websocketState from "./websocketState";
import  generalSlice  from "./generalUseState";


const rootReducer = combineReducers({
  menu: menuReducer,
  contact: contactReducer,
  posts: postReducer,
  user: userSlice,
  errors: errorSlice,
  secs: secretsSlice,
  dash: DashSlice,
  postForm: postsFormSlice,
  recaptcha: captchaSlice,
  emailsState: emailSlice,
  websocket: websocketState,
  general: generalSlice,

});

export default rootReducer;
