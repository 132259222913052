import DatePicker from "react-datepicker";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmailStateFields } from "../../redux/reducers/EmailFormState";

function DatePickerComponent({ daysAgo, field }) {
  const dateStringInState = useSelector((state) => state.emailsState[field]);
  const dispatch = useDispatch();

  useEffect(() => {
    const newDate = new Date();
    if (daysAgo) {
      newDate.setDate(newDate.getDate() - daysAgo);
    }
    dispatch(setEmailStateFields({ field, value: newDate.toISOString() }));
  }, [daysAgo, field, dispatch]);

  const setTheDate = (theDate) => {
    const dateValue = theDate ? theDate.toISOString() : "";
    dispatch(setEmailStateFields({ field, value: dateValue }));
  };

  return (
        <DatePicker
          selected={dateStringInState ? new Date(dateStringInState) : null}
          onChange={(date) => setTheDate(date)}
          closeOnScroll={true}
          isClearable
          placeholderText="Select a date"
        />
  );
}

export default DatePickerComponent;
