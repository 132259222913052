import EmailSideBar from "./emailSubComponents/EmailSideBar";
import EmailNavBar from "./emailSubComponents/EmailNavBar";
import DisplayEmailsPage from "./emailSubComponents/DisplayEmailGenerl";
import { useState } from "react";

function DashEmails() {
  const [showSidebar, setShowSidebar] = useState(false);


  const dataToDashEmails = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="container-fluid App">
      <EmailNavBar dataFn={dataToDashEmails} />
      <EmailSideBar
        isSideBarOpen={showSidebar}
        toggleSideBar={dataToDashEmails}
      />
      <DisplayEmailsPage />
    </div>
  );
}

export default DashEmails;
