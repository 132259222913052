import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { setActiveMenu } from "../redux/reducers/activeMenuState";
import { Card, CardBody } from "react-bootstrap";


function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(setActiveMenu("home"));
    })();
  }, [dispatch]);

  return (
    <Container className="full-height-container main-wrapper">
      <Row className="  my-2 d-flex justify-content-between content-h-s">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="align-items-center justify-content-center p-1 d-flex my-1  "
        >
          <Row className="max-heigth-100max-width-100 p-1 d-flex justify-content-between align-items-center">
            <Col
              xs={12}
              sm={12}
              md={8}
              lg={8}
              className=" justify-content-center p-1 d-flex my-1 "
            >
              <Row className="height-100 p-1   ">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="max-heigth-50 p-4 align-items-center d-flex my-1"
                >
                  <Card className="shadow" style={{ border: "none" }}>
                    <CardBody>
                      <Row>
                        <Col>
                          <blockquote className="blockquote">
                            <p>
                              "Quality is not an act, it is a habit. Through
                              rigorous testing, we not only discover the flaws
                              but also illuminate the path to excellence. Let's
                              build something remarkable together."
                            </p>
                            <footer className="blockquote-footer">
                              <cite title="Source Title">
                                Inspired by Aristotle's philosophy on
                                excellence, adapted for the world of software
                                testing.
                              </cite>
                            </footer>
                          </blockquote>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className="max-heigth-50  p-4 align-items-center d-flex   my-1"
                        >
                          <p>
                            <strong>
                              Yashar here, an SDET with 6.5 years of taming wild
                              code in Librarian, CRM, and communication realms.
                              Precision meets flair in my world of testing,
                              where bugs fear to tread.
                            </strong>
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className=" justify-content-center p-1 d-flex my-1 px-1  "
            >
              <img
                src="images/lars.jpg"
                className="img-fluid image"
                alt="person"
              />
            </Col>
          </Row>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="align-items-center justify-content-center p-2 d-flex flex-column my-1 mx-0  "
        >
          <Card className="shadow" style={{ border: "none" }}>
            <CardBody>
              <h3>Mastering the Art of Software Testing</h3>
              <p>
                I'm an experienced QA automation engineer with a knack for
                getting to the heart of technology. My tool belt includes Java
                and C#, and I'm also familiar with a dash of JavaScript. These
                aren't just programming languages; they're my instruments to
                create flawless digital experiences. Using a variety of
                libraries and frameworks, I've been able to craft tests that
                delve into APIs, databases, and user interfaces. Whether it's
                ensuring that a database retrieves information correctly or
                making certain that a user's click leads them exactly where it
                should, I'm all about precision and efficiency. I believe in
                keeping up with modern testing methods to offer only the best in
                the fast-evolving tech landscape.
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="align-items-center justify-content-center p-2 d-flex flex-column my-1 mx-0  "
        >
          <Card className="shadow" style={{ border: "none" }}>
            <CardBody>
              <h3>A Self-Taught Journey Through Cutting-Edge Technology</h3>
              <p>
                My adventure into software testing began in 2017, and it's been
                a thrilling ride since then. Self-taught and driven by
                curiosity, I've worked with innovative companies that are
                shaping the digital world. First up was Decihire, where I honed
                my skills in Java to automate testing for a revolutionary
                Librarian App. Then came CRMLY, where I again leveraged Java and
                its accompanying tools to ensure the CRM app's robust
                performance. Most recently, I've been with CCI, working on a
                complex communication application that powers cellular carriers.
                Here, I switched gears to C# and .NET, embracing new libraries
                and frameworks. These experiences are more than jobs; they're
                chapters in my continuing story of growth and innovation.
              </p>
            </CardBody>
          </Card>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className="align-items-center justify-content-center p-1 d-flex flex-column my-1  "
        >
          <Card className="shadow" style={{ border: "none" }}>
            <CardBody>
              <h3>Why Testing Matters in Today's Digital World</h3>
              <p>
                In the bustling world of technology, testing is like the
                unassuming hero. It's the process that ensures every click,
                every swipe, every tap works precisely as it's supposed to.
                Think of software testing as the invisible security guard,
                keeping the digital experience smooth and secure. But it's not
                just about catching bugs; it's about understanding how users
                interact with an application. Modern testing involves real-world
                scenarios, simulating actual user behavior. By adopting the
                latest practices, such as Continuous Integration and
                Behavior-Driven Development, we can build software that not only
                functions flawlessly but also resonates with the end-users. It's
                not just about technology; it's about connecting technology with
                humanity.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
