import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import SigningBtns from "./Signing";
import {
  getRolePermission,
  isUserSignedIn,
} from "../redux/reducers/secretState";

function NavBar() {
  const [expanded, setExpanded] = useState(false);
  const activeLink = useSelector((state) => state.menu.activeMenu);
  const isSignedIn = useSelector((state) => state.secs.isSignedIn);
  const rolePermission = useSelector((state) => state.secs.rolePermission);
  const fullName = useSelector((state) => state.contact.fullName);
  let name = fullName ? fullName.replace(" ", "_") : "messages";

  const dispatch = useDispatch();

  useEffect(() => {
    if (rolePermission === null) {
      dispatch(getRolePermission());
    }
    if (isSignedIn === null) {
      dispatch(isUserSignedIn(false));
    }
  }, [dispatch, isSignedIn, activeLink, rolePermission]);

  return (
    <Navbar bg="light" expand="lg" expanded={expanded} >
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              as={Link}
              to="/"
              onClick={() => setExpanded(false)}
              active={activeLink === "home"}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={`/contact/${name}`}
              onClick={() => setExpanded(false)}
              active={activeLink === "contact"}
            >
              Contact
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/AboutMeDetail"
              onClick={() => setExpanded(false)}
              active={activeLink === "AboutMeDetail"}
            >
              About Me
            </Nav.Link>
            
            {isSignedIn && rolePermission && (
              <Nav.Link
                as={Link}
                to="/AdDashPr"
                onClick={() => setExpanded(false)}
                active={activeLink === "dashboard"}
              >
                Dashboard
              </Nav.Link>
            )}
          </Nav>
          <div className="ml-auto">
            <SigningBtns isSignedIn={isSignedIn} />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
