import { deleteEmailsFromState } from "../reducers/EmailFormState";
import {
  addEmailInEmailState,
  connected,
  disconnected,
  updateEmailInState,
} from "../reducers/websocketState";

const websocketMiddleware = (store) => {
  let socket = null;

  const onOpen = () => {
    console.log("WebSocket connected");

    store.dispatch(connected());
  };

  const onClose = () => {
    console.log("WebSocket disconnected"); 

    store.dispatch(disconnected());
  };

  const onMessage = (event) => {
    try {
      const message = JSON.parse(event.data);
      console.log(message);

      switch (message.operation) {
        case "INSERT":
          store.dispatch(addEmailInEmailState(message.data));
          break;
        case "DELETE":
          store.dispatch(deleteEmailsFromState(message.data));
          break;
        case "UPDATE":
          store.dispatch(updateEmailInState(message.data));
          break;
        default:
      }
    } catch (err) {
      console.error("Error handling message:", err);
    }
  };

  return (next) => (action) => {
    switch (action.type) {
      case "websocket/connect":
        if (socket !== null) {
          socket.close();
        }

        socket = new WebSocket(process.env.REACT_APP_WS_URL); 
        socket.onmessage = onMessage;
        socket.onclose = onClose;
        socket.onopen = onOpen;
        break;
      case "websocket/disconnect":
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case "websocket/send":
        if (socket !== null) {
          socket.send(JSON.stringify(action.payload));
        }
        break;
      default:
        return next(action);
    }
  };
};

export default websocketMiddleware;
