import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function AboutMeHighlights() {

    const { posts} = useSelector((state) => state.posts);

   const posts1 = posts.filter((item)=> item.title === 'Mastering the Tools of an SDET');

    const Poses = posts1.map((m) => (
        <Col
          key={m._id}
          xs={12}
          sm={12}
          md={12}
          lg={5}
          className="d-flex flex-column flex-grow-1 my-lg-3 p-1 px-3"
        >
          <div className="d-flex flex-row flex-grow-1">
            {/* <Fade duration={2000} delay={200}> */}
              <Card className=" shadow h-100 " style={{ border: "none" }}>
                <Card.Body>
                  <div>{m.category}</div>
                  <h3>{m.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: m.content }} />
                </Card.Body>
              </Card>
            {/* </Fade> */}
          </div>
        </Col>
      ));

    return ( 
        <>
            <Row>
                {Poses}
            </Row>
        </>
     );
}

export default AboutMeHighlights;