import { createSlice } from "@reduxjs/toolkit";
import { cleanStorage, updateUserField } from "./UserState";

import { setRolePermissionErr } from "./GlobalErrorState";

const initialState = {
  isSignedIn: null,
  rolePermission: null,
  showPinModal: true,
  showThanksMessage: false,
  EmailModalStatus: false,
  notificationModal:false,
  pickNotificationMessageFrom:null,
};

const secretsSlice = createSlice({
  name: "secrets",
  initialState,
  reducers: {
    setRolePermission: (state, action) => {
      state.rolePermission = action.payload;
    },
    setShowPinModal: (state, action) => {
      state.rolePermission = action.payload;
    },
    setShowThanksMessage: (state, action) => {
      state.showThanksMessage = action.payload;
    },
    setEmailModalStatus: (state, action) => {
      state.EmailModalStatus = action.payload;
    },
    setNotificationModal: (state, action) => {
      state.notificationModal = action.payload;
    },
    setPickNotificationMessageFrom: (state, action) => {
      state.pickNotificationMessageFrom = action.payload;
    },
    userSignInStatusChange: (state, action) => {
      if (typeof action.payload === "boolean") {
        return {
          ...state,
          isSignedIn: action.payload,
        };
      }
    },
  },
});

export const {
  userSignInStatusChange,
  setRolePermission,
  setShowPinModal,
  setShowThanksMessage,
  setEmailModalStatus,
  setNotificationModal,
  setPickNotificationMessageFrom,
} = secretsSlice.actions;

export const getRolePermission = () => async (dispatch, getState) => {
  try {
    const res = await fetch(
      process.env.REACT_APP_API_BaseURl + "/auth/getPermission",
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();

    if (res.ok) {
      dispatch(setRolePermission(data.permited));
    } else {
      dispatch(setRolePermissionErr("Couldn't Authorize your permissions"));
    }
  } catch (err) {
    console.log("Error in fetch");
  }
};

export const isUserSignedIn =
  (getUser = false) =>
  async (dispatch) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BaseURl + "/auth/google/isSignedin",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            getUser: getUser,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.user) {
          dispatch(userSignInStatusChange({ status: true }));
          for (const key in data.user) {
            const value = data.user[key];
            dispatch(updateUserField({ field: key, value }));
          }
        }

        if (data.signedIn) {
          dispatch(userSignInStatusChange(true));
        } else {
          dispatch(userSignInStatusChange(false));
          dispatch(cleanStorage());
        }
      } else {
        console.error("Failed to check sign-in status:", response.statusText);
      }
    } catch (err) {
      console.log("Error in fetch");
    }
  };

export default secretsSlice.reducer;
