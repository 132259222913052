import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import LoaderC from "./subComponents/Loader";
import MessageBox from "./subComponents/Mess";
import { verifyRecaptchaAndSubmitForm} from "../redux/reducers/captchaState";
import { setActiveMenu } from "../redux/reducers/activeMenuState";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import {
  updateField,
  toggleContactMethod,
} from "../redux/reducers/ContactState";
import { getMesagesByUser } from "../redux/reducers/UserState";
import { isUserSignedIn } from "../redux/reducers/secretState";
import {
  validateFullName,
  validateCompanyName,
  validateEmail,
  validatePhoneNumber,
  validateLinks,
  validateMessage,
} from "../utilities/validationUtils";

function Contact() {
  const dispatch = useDispatch();
  const contactData = useSelector((state) => state.contact);
  const showThanksMessage = useSelector(
    (state) => state.secs.showThanksMessage
  );
  const isLoading = useSelector((state) => state.contact.isLoading);
  const user = useSelector((state) => state.user);
  const globalErrs = useSelector((state) => state.errors);
  const userIsSignedIn = useSelector((state) => state.secs.isSignedIn);
  const captchaToken = useSelector((state) => state.recaptcha.token);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const checkUserStatusAndFetchData = async () => {
      try {
        dispatch(setActiveMenu("contact"));
        if (userIsSignedIn) {
          dispatch(getMesagesByUser());
        } else {
          dispatch(isUserSignedIn(true));
        }
      } catch (err) {
        console.log("Something went wrong");
      }
    };

    checkUserStatusAndFetchData();
  }, [dispatch, userIsSignedIn]);

  const validateField = (field, value) => {
    let newErrors = { ...errors };
    const errorMessage = {
      fullName: validateFullName,
      companyName: validateCompanyName,
      normalizedEmail: validateEmail,
      phoneNumber: validatePhoneNumber,
      links: validateLinks,
      message: validateMessage,
    }[field](value);

    if (errorMessage) {
      newErrors[field] = errorMessage;
    } else {
      delete newErrors[field];
    }

    setErrors(newErrors);
  };

  const validateAllFields = () => {
    let newErrors = {
      fullName: validateFullName(contactData.fullName),
      companyName: validateCompanyName(contactData.companyName),
      email: validateEmail(contactData.normalizedEmail),
      phoneNumber: validatePhoneNumber(contactData.phoneNumber),
      links: validateLinks(contactData.links),
      message: validateMessage(contactData.message),
    };

    Object.keys(newErrors).forEach(
      (key) => newErrors[key] == null && delete newErrors[key]
    );

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const RenderMessages = () => {
    if (user && user.messageingThread) {
      return user.messageingThread.map((m) => (
        <MessageBox key={m._id} messageData={m} userId={user._id} />
      ));
    }
    return null;
  };

  const handleSubmit = async () => {
    if (validateAllFields()) {
      
      if (!captchaToken) {
        console.error("reCAPTCHA token is null or undefined");
        return;
      }
      dispatch(verifyRecaptchaAndSubmitForm(contactData));
    } else {
      alert("There are errors in your form, please fix them before submitting!");
    }
  };

  const handleFieldChange = (field, value) => {
    dispatch(updateField({ field, value }));
    validateField(field, value);
  };

  const handleContactMethodToggle = (method) => {
    dispatch(toggleContactMethod(method));
  };

  const classNameOfMessage = () => {
    if (userIsSignedIn && !contactData.success) {
      return "warning";
    } else if (userIsSignedIn && contactData.success) {
      return "message-OK";
    } else if (!userIsSignedIn) {
      return "message-OK";
    }
  };

  return (
    <div className="container-fluid p-5">
      {isLoading ? (
        <LoaderC />
      ) : (
        <div className="        ">
          <div>{userIsSignedIn && !showThanksMessage && RenderMessages()}</div>
          <div
            className={`${classNameOfMessage()} d-flex justify-content-center`}
          >
            {"" || globalErrs.formSubmitMessageApi}
          </div>
          <h5 className="mb-5">Contact Information</h5>

          <Form>
            <Row>
              <Col md={4} className="formCol">
                <FloatLabel>
                  <InputText
                    className="InputText"
                    id="fullNameBox"
                    value={contactData.fullName}
                    onChange={(e) => {
                      handleFieldChange("fullName", e.target.value);
                    }}
                    disabled={userIsSignedIn}
                  />
                  <label htmlFor="fullNameBox">Full Name</label>
                </FloatLabel>
                {errors.fullName && (
                  <div className="text-danger">{errors.fullName}</div>
                )}
              </Col>
              <Col md={4} className="formCol">
                <FloatLabel>
                  <InputText
                    className="InputText"
                    id="companyNameBox"
                    value={contactData.companyName}
                    onChange={(e) => {
                      handleFieldChange("companyName", e.target.value);
                    }}
                  />
                  <label htmlFor="companyNameBox">Company Name</label>
                </FloatLabel>
                {errors.companyName && (
                  <div className="text-danger">{errors.companyName}</div>
                )}
              </Col>
              <Col md={4} className="formCol">
                <FloatLabel>
                  <InputText
                    type="email"
                    className="InputText"
                    id="normalizedEmailBox"
                    onChange={(e) => {
                      handleFieldChange("normalizedEmail", e.target.value);
                    }}
                    value={contactData.normalizedEmail}
                    disabled={userIsSignedIn}
                  />
                  <label htmlFor="normalizedEmailBox">Email</label>
                </FloatLabel>
                {errors.normalizedEmail && (
                  <div className="text-danger">{errors.normalizedEmail}</div>
                )}
              </Col>
              <Col md={3} className="formCol">
                <FloatLabel>
                  <InputText
                    className="InputText"
                    id="phoneNumberBox"
                    onChange={(e) => {
                      handleFieldChange("phoneNumber", e.target.value);
                    }}
                    value={contactData.phoneNumber}
                  />
                  <label htmlFor="phoneNumberBox">Phone</label>
                </FloatLabel>
                {errors.phoneNumber && (
                  <div className="text-danger">{errors.phoneNumber}</div>
                )}
              </Col>
              <Col md={6} className="formCol">
                <FloatLabel>
                  <InputText
                    className="InputText"
                    id="linksBox"
                    onChange={(e) => {
                      handleFieldChange("links", e.target.value);
                    }}
                    value={contactData.links}
                  />
                  <label htmlFor="linksBox">Links</label>
                </FloatLabel>
                {errors.links && (
                  <div className="text-danger">{errors.links}</div>
                )}
              </Col>
            </Row>
            <Row className="my-5">
              <h5>How can I contact you?</h5>
              <Col md={1} className="mb-2">
                <Form.Check
                  type="checkbox"
                  name="emailContact"
                  label="Email"
                  checked={
                    contactData.preferredContactMethods.includes("email") &&
                    true
                  }
                  onChange={() => {
                    handleContactMethodToggle("email");
                  }}
                  tabIndex={6}
                />
              </Col>
              <Col md={1} className="mb-2">
                <Form.Check
                  name="phoneContact"
                  type="checkbox"
                  label="Phone"
                  onChange={() => {
                    handleContactMethodToggle("phone");
                  }}
                  tabIndex={7}
                />
              </Col>
            </Row>
            <Row className="my-5">
              <Col md={12} className="formCol">
                <FloatLabel>
                  <InputTextarea
                    id="messageBox"
                    rows={10}
                    onChange={(e) => {
                      handleFieldChange("message", e.target.value);
                    }}
                    value={contactData.message}
                    autoResize
                    style={{ width: "100%" }}
                  />
                  <label htmlFor="messageBox">
                    <strong>Message</strong>
                  </label>
                </FloatLabel>

                {errors.message && (
                  <div className="text-danger">{errors.message}</div>
                )}
                <div>{2000 - contactData.message.length}/2000</div>
              </Col>
            </Row>
            <Row className="my-5 justify-content-center">
              <Col sm={6} md={6} lg={3}>
                <Form.Control
                  name="submitBtn"
                  as="button"
                  type="button"
                  className="btn btn-primary"
                  tabIndex={9}
                  onClick={handleSubmit}
                >
                  Send
                </Form.Control>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

export default Contact;
