import { setEmailModalStatus } from "../../redux/reducers/secretState";
import ComposeEmailModal from "./ComposeEmailComponent";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { updateEmailFields } from "../../redux/reducers/EmailFormState";
import AttachmentDisplay from "./AttachmentNamesDisplay";

function EmailDetail({ mail }) {
  const dispatch = useDispatch();
  const sentFrom = useSelector(
    (state) => state.emailsState.emailSideBarSelection
  );

  // Sanitizing mail.text
  const sanitizedMailText = DOMPurify.sanitize(mail.text);

  const handleReply = () => {
    const fromEmail = mail.from.match(/<([^>]+)>/)?.[1] || mail.from;
    dispatch(updateEmailFields({ field: "to", value: fromEmail }));
    dispatch(
      updateEmailFields({
        field: "references",
        value: `${mail.references} ${mail.messageid}`,
      })
    );

    dispatch(updateEmailFields({ field: "sentFrom", value: sentFrom }));

    dispatch(updateEmailFields({ field: "inReplyTo", value: mail.messageid }));

    dispatch(updateEmailFields({ field: "cc", value: mail.cc }));
    dispatch(updateEmailFields({ field: "bcc", value: mail.bcc }));

    const replySubject = mail.subject.includes("Re:")
      ? mail.subject
      : `Re: ${mail.subject}`;
    dispatch(updateEmailFields({ field: "subject", value: replySubject }));
    dispatch(setEmailModalStatus(true));
  };


  return (
    <div className="detailEmail">
      <div>
        <strong>From:</strong> {mail.from}
        <span className="d-flex justify-content-end ">
          {new Date(mail.date).toLocaleString()}
        </span>
      </div>

      <hr />
      <div>
        <strong>To:</strong> {mail.to}
      </div>
      <hr />
      <div>
        <strong>Subject:</strong> {mail.subject}
      </div>
      <hr />
      {mail.attachments ? <AttachmentDisplay >{mail.filesattached}</AttachmentDisplay> : ""}

      <div dangerouslySetInnerHTML={{ __html: sanitizedMailText }}></div>
      <hr />
      <div>
        <button className="btn btn-primary" onClick={() => handleReply()}>
          Reply
        </button>
      </div>
      <ComposeEmailModal />
    </div>
  );
}

export default EmailDetail;
