import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Messages from "./DashMessages";
import DashBoardPosts from "./DashBoardPosts";
import DashEmails from "./DashEmails";
import { setActiveMenu } from "../redux/reducers/activeMenuState";

const DashboardMain = () => {

  const dispatch = useDispatch();

    useEffect(()=>{
      dispatch(setActiveMenu("dashboard"));
    },[dispatch])

  return (
    <>
      <Nav variant="tabs" defaultActiveKey="/AdDashPr">
        <Nav.Item>
          <Nav.Link as={NavLink} to="/AdDashPr/messages">
            Messages
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/AdDashPr/posts">
            Posts
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/AdDashPr/emails">
            Emails
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Routes>
        <Route path="messages" element={<Messages />} />
        <Route path="posts" element={<DashBoardPosts />} />
        <Route path="emails" element={<DashEmails />} />
        <Route index element={<Messages />} />
      </Routes>
    </>
  );
};

export default DashboardMain;
