import { createSlice } from "@reduxjs/toolkit";

import { setNotificationErr, setUserByIdErr } from "./GlobalErrorState";

const initialState = {
  notificationData:[],
  userById: null,
  expandAllMessages:false,
  sideBarShow:false
};

const DashSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.notificationData = action.payload;
    },
    setUserById: (state, action) => {
      state.userById = action.payload;
    },
    setExpandAllMessages: (state, action) => {
      state.expandAllMessages=action.payload;
    },
    setSideBarShowReverse : (state) =>{
      state.sideBarShow = !state.sideBarShow;
    },
    setSideBarShow : (state, action) =>{
      state.sideBarShow = action.payload;
    },
  },
});
export const { setData, setUserById, setExpandAllMessages, setSideBarShowReverse, setSideBarShow } = DashSlice.actions;



  export const getUserById = (id) => async (dispatch) => {
    try {
      const res = await fetch(
        process.env.REACT_APP_API_BaseURl + `/client/getUserById/${id}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();

      if (data.success) {
        dispatch(setUserById(data.data));
      } else {
        dispatch(setUserByIdErr(data.err));
      }
      
    } catch (err) {
      dispatch(setUserByIdErr(err.message || "An error occurred"));
    }
  };



  export const getNotifications = () => async (dispatch) => {
    try {
      const res = await fetch(
        process.env.REACT_APP_API_BaseURl + "/Messages/getNewMesages",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();

      if (res.ok) {
        dispatch(setData(data.data));
      } else {
        dispatch(setNotificationErr(data.err));
      }
    } catch (err) {
      dispatch(setNotificationErr(err));
    }
  };



export default DashSlice.reducer;
