import React, { useState } from 'react';
import Pagination from "react-bootstrap/Pagination";
import { GetEmailsFromDb_GeneralView, setPageNumber } from "../../redux/reducers/EmailFormState";
import { useDispatch, useSelector } from 'react-redux';

function EmailPagination({ totalEmails }) {
  const dispatch = useDispatch();
  const curentFolder = useSelector((state) => state.emailsState.emailSideBarSelection);
  const emailsPerPage = 10;
  const numberOfPages = Math.ceil(totalEmails / emailsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const getPaginationBase = (page) => {
    if (page < 4) {
      return 1;
    } else if (page > numberOfPages - 4) {
      return numberOfPages - 7;
    } else {
      return page - 3;
    }
  };

  const [paginationBase, setPaginationBase] = useState(getPaginationBase(1));

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPageNumber(pageNumber);
    setPaginationBase(getPaginationBase(pageNumber));
    dispatch(GetEmailsFromDb_GeneralView(curentFolder, pageNumber));
  };

  const renderPaginationItems = () => {
    let items = [];
    let startPage = paginationBase;
    let endPage = Math.min(startPage + 7, numberOfPages);

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} active={1 === currentPage} onClick={() => handlePageClick(1)}>
          {1}
        </Pagination.Item>
      );
    }

    if (startPage > 2) {
      items.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageClick(number)}>
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < numberOfPages - 1) {
      items.push(<Pagination.Ellipsis key="end-ellipsis" />);
    }

    if (endPage < numberOfPages) {
      items.push(
        <Pagination.Item key={numberOfPages} active={numberOfPages === currentPage} onClick={() => handlePageClick(numberOfPages)}>
          {numberOfPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageClick(1)} />
      <Pagination.Prev onClick={() => handlePageClick(Math.max(1, currentPage - 1))} />
      {renderPaginationItems()}
      <Pagination.Next onClick={() => handlePageClick(Math.min(numberOfPages, currentPage + 1))} />
      <Pagination.Last onClick={() => handlePageClick(numberOfPages)} />
    </Pagination>
  );
}

export default EmailPagination;
