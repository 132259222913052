import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFlip } from '../redux/reducers/generalUseState';
import FlipperPage from './subComponents/FliperPage';
import AboutMeDetail from './AboutMeDetail';
import AboutMeHighlights from './AboutMeHighlights';
import { Button } from 'primereact/button';

const AboutMeMain = () => {
  const dispatch = useDispatch();
  const front = useSelector(state=> state.general.isFlipped)

  return (
    <>
    <div className='d-flex justify-content-center align-items-center' >

      <Button onClick={() => dispatch(toggleFlip())} className='my-4'>{!front? `Explain in detail` : "I don't have time, get to the point"}</Button>
    </div>
      <FlipperPage>
        <AboutMeHighlights/>
        <AboutMeDetail />
      </FlipperPage>
    </>
  );
};

export default AboutMeMain;
