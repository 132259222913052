import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    posts: [],
    error: '',
    isLoading: false,
    postForm: {
        id: '',
        category: '',
        title: '',
        content: ''
    }
};

const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        setPostForm: (state, action) => {
            const { field, value } = action.payload;
            state.postForm[field] = value;

        },

        setAllPost: (state, action) => {
            const { data } = action.payload;
            state.posts = data;
        },
        addPost: (state, action) => {
            state.posts.push(action.payload);
        },
        getAPost: (state, action) => {
            const thePost = state.posts.filter(p => p._id === action.payload)[0];
            if (thePost) {
                for (const key in thePost) {
                    state.postForm[key] = thePost[key];
                }
            }

        },
        editAPost: (state, action) => {

            const { _id } = action.payload.post;

            const index = state.posts.findIndex(p => p._id === _id);
            if (index !== -1) {
                state.posts[index] = action.payload.post;
            }


        },
        deleteAPost: (state, action) => {
            state.posts = state.posts.filter(p => p._id !== action.payload);
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        rsetForm: (state) => {
            state.postForm = initialState.postForm;
        }
    }
});

export const { setAllPost, addPost, getAPost, editAPost, deleteAPost, setError ,setPostForm, setIsLoading } = postSlice.actions;

const mes = 'Something went wrong... please try again later.';

export const getAllPost = () => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        const res = await fetch(
          process.env.REACT_APP_API_BaseURl + "/posts/getPosts",
          {
            method: "GET",
            credentials:"include",
            headers: { "Content-Type": "application/json" },
          }
        );

        const posts = await res.json();

        if (res) {
            dispatch(setAllPost(posts));
            dispatch(setIsLoading(false));
        }

    } catch (err) {
        dispatch(setError(mes));
        dispatch(setIsLoading(false));
    }


}


export const editPost = (data) => async (dispatch) => {

    dispatch(setIsLoading(true));

    try {

        const res = await fetch("https://lapisel-dev.com/Contact/editYasPost", {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        const editedPost = await res.json();

        if (editedPost && editedPost.success) {
            dispatch(editAPost(editedPost.post));
            dispatch(setIsLoading(false));
        } else {
            dispatch(setIsLoading(false));
            throw Error(mes);
        }

    } catch (err) {
        dispatch(setIsLoading(false));
        dispatch(setError(err));
    }
}
export const deletePost = (id) => async (dispatch) => {

    dispatch(setIsLoading(true));

    try {

        const res = await fetch(`https://lapisel-dev.com/Contact/deleteYasPost/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
            }
        })

        const deletedPost = await res.json();

        if (deletedPost.success) {
            dispatch(deleteAPost(id));
            dispatch(setIsLoading(false));
        } else {
            dispatch(setIsLoading(false));
            throw Error('Post deletion respone error');
        }

    } catch (err) {
        dispatch(setError(mes));
        dispatch(setIsLoading(false));
    }
}



export default postSlice.reducer;
