import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { setSideBarShow } from "../../redux/reducers/dashState";
import { Container, Row } from "react-bootstrap";

function SideBar({ children }) {
  const showSideBar = useSelector((state) => state.dash.sideBarShow);
  const dispatch = useDispatch();

  useEffect(() => {}, [showSideBar, dispatch]);

  const handleClose = () => {
    dispatch(setSideBarShow(false));
  };

  return (
    <>
      <Offcanvas
        show={showSideBar}
        style={{ width: "70%" }}
        onHide={handleClose} 
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Row className="align-items-center justify-content-center ">
              {children}
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SideBar;
