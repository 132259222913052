import SearchBox from "../subComponents/SearchBox";
import { setEmailModalStatus } from "../../redux/reducers/secretState";
import { useDispatch, useSelector } from "react-redux";
import ComposeEmailModal from "./ComposeEmailComponent";
import {
  // GetEmailsFromDb_GeneralView,
  GetEmailsThreadsByDate_Navbar,
  resetEmail,
} from "../../redux/reducers/EmailFormState";
import DatePickerComponent from "../subComponents/DatePickerComponent";
import { Col, Container, Row } from "react-bootstrap";

function EmailNavBar({ dataFn }) {
  const dispatch = useDispatch();

  const selectedFolder = useSelector(
    (state) => state.emailsState.emailSideBarSelection
  );
  const startDate = useSelector((state) => state.emailsState.startDate);
  const endDate = useSelector((state) => state.emailsState.endDate);

  const handleShow = () => {
    dispatch(resetEmail());
    dispatch(setEmailModalStatus(true));
  };

  const handleGetEmails = () => {
    dispatch(GetEmailsThreadsByDate_Navbar(selectedFolder, startDate, endDate));
  };

  return (
    <Container fluid>
      <Row className="emailNavbar shadow mt-3">
        <Col className="d-flex align-items-center my-2" xs={2} sm={2} md={1} lg={1}>
          <button
            className="hamburger-menu"
            aria-label="Toggle navigation"
            onClick={() => dataFn()}
          >
            <span className="menu-bar"></span>
            <span className="menu-bar"></span>
            <span className="menu-bar"></span>
          </button>
        </Col>
        <Col className=" my-2" xs={10} sm={10} md={4} lg={3}>
          <SearchBox />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={7}
          lg={4}
          className="d-flex align-items-center justify-content-center my-2 px-3"
        >
          <div className="m-3 w-md-50">
            <span><strong>{`From: `}</strong></span>
            <DatePickerComponent daysAgo={7} field={"startDate"} />
          </div>
          <div className="m-3 w-md-50">
            <span><strong>{`To: `}</strong></span>
            <DatePickerComponent field={"endDate"} />
          </div>
        </Col>
        <Col className="d-flex align-items-center my-2" sm={12} md={6} lg={2}>
          <button className="btn btn-primary w-100" onClick={handleGetEmails}>
            Get Emails
          </button>
        </Col>

        <Col className="d-flex align-items-center my-2" sm={12} md={6} lg={2}>
          <button
            className="btn btn-warning w-100"
            onClick={() => {
              dispatch(handleShow);
            }}
          >
            Compose Email
          </button>
          <ComposeEmailModal />
        </Col>
      </Row>
    </Container>
  );
}

export default EmailNavBar;
