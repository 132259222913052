import { useDispatch, useSelector } from "react-redux";
import {
  setEmailSideBarSelection,
  setRepliesPage,
} from "../../redux/reducers/EmailFormState";
import { extras } from "../../utilities/EmailUtils";

function EmailSideBar({ isSideBarOpen, toggleSideBar }) {
  const dispatch = useDispatch();
  const folders = useSelector((state) => state.emailsState.foldersAndCounts);
  const selectedFolder = useSelector((state) => state.emailsState.emailSideBarSelection);

  const handleFolderClick = async (folder) => {
    dispatch(setEmailSideBarSelection(folder));
    dispatch(setRepliesPage(false));
  };

  const renderFolders = () => {
    return folders.map((each) => (
      <div className="email-sidebar_folder" key={each.folder}>
        <h4
          className={`${each.folder === selectedFolder? "folder-header txt_gold" : "folder-header"}`}
          onClick={() => {
            handleFolderClick(each.folder);
            toggleSideBar();
          }}
        >
          {each.folder}
          <span className="totalcount">{`(${each.totalcount})`}</span>
          <span className="spacer"></span>{" "}
          <span className="unreadcount">{` ${each.unreadcount}`}</span>
        </h4>
      </div>
    ));
  };

  const renderExtraFolders = () => {
    const extrasFolders = extras();
    return extrasFolders.map((each) => (
      <div className={`${each === selectedFolder? "email-sidebar_folder txt_gold" : "email-sidebar_folder"}`} key={each}>
        <h4
          onClick={() => {
            handleFolderClick(each);
            toggleSideBar();
          }}
        >
          {each}
        </h4>
      </div>
    ));
  };

  return (
    <>
      <div
        className={`sideBar_container ${isSideBarOpen ? "visible" : "hidden"} `}
      >
        <div className="sidebarBox">{renderFolders()}</div>
        <div className="horizontalLine" />
        <div className="sidebarBox">{renderExtraFolders()}</div>
      </div>
    </>
  );
}

export default EmailSideBar;
