import { useDispatch, useSelector } from "react-redux";
import { setToBeDeletedArray } from "../../redux/reducers/EmailFormState";
import Star from "../subComponents/Star";
import { Col, Row } from "react-bootstrap";
import { FiPaperclip } from 'react-icons/fi';

function EmailRow({ email, handleEmailDetail}) {
  const selectedFolder = useSelector(
    (state) => state.emailsState.emailSideBarSelection
  );

  const msgIdAsVar = email.messageid || email.messageId;
  const dispatch = useDispatch();

  const handleSubstring = (text, start, end) => {
    if (text === undefined) {
      return "";
    }
    let tempText = Array.from(text);
    let sub = tempText.slice(start, end);
    return sub.join("");

  };

  const handleFromAddressDisplay = (value) => {
    const newValue = value.replace(/<[^>]+>/g, "").trim();
    if (newValue.length === 0) {
      const matches = value.match(/<([^>]+)>/);
      if (matches) {
        return matches[1];
      } else {
        return value;
      }
    }
    return newValue;
  };

  const manageInToBeDeletedArray = (msgId) => {
    dispatch(setToBeDeletedArray(msgId));
  };

  return (
    <Row
      className="email_row my-1 align-items-center"
      key={email.messageId}
      onClick={(e) => {
        e.stopPropagation();
        handleEmailDetail(email);
      }}
    >
      <Col
      xs={3}
        sm={2}
        md={1}
        lg={1}
        className={` mail_cell ${!email.isread ? "email_sender" : ""}`}
      >
        {selectedFolder !== "Trash" ? <Star email={email} /> : ""}
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            type="checkbox"
            name="check"
            onChange={(e) => {
              e.stopPropagation();
              manageInToBeDeletedArray(msgIdAsVar);
            }}
          ></input>
        </span>

      </Col>
      <Col xs={9} sm={4} md={3} lg={2} className="">
        <span className="check"> {handleFromAddressDisplay(email.from)}</span>
      
      </Col>
      <Col
        xs={9}
        sm={4}
        md={5}
        lg={3}
        className={` mail_cell ${!email.isread ? "email_subject" : ""} `}
      >
        <span className="mx-3">{email.attachments > 0 ? <FiPaperclip /> :""}</span>
        {handleSubstring(email.subject, 0, 25)}
      </Col>
      <Col sm={2} md={4} lg={4} className="mail_cell d-none d-lg-block">
        {email.text.length > 90
          ? handleSubstring(email.text, 0, 65) + "..."
          : email.text}{" "}
      </Col>
      <Col xs={3} sm={2} md={3} lg={2} className="mail_cell font-sm">
        {new Date(email.date).toLocaleString() || ""}
      </Col>
    </Row>
  );
}

export default EmailRow;
